import CoreNavBar from "../nav/CoreNavBar";
import React, {useState} from "react";
import axios from "axios";
import hostName from "../../constants/hosts";
import {Button, Card} from "react-bootstrap";
import {ChevronRight, PersonAdd} from "react-bootstrap-icons";
import {MDBBtn} from "mdb-react-ui-kit";
import FindFriendsModal from "../profile/FindFriendsModal";
import ActionBar from "../list/ActionBar";

const RequestsPage = () => {
    const [hasFetchedFriendRequests, setHasFetchedFriendRequests] = useState(false);
    const [friendRequests, setFriendRequests] = useState(getUserFriendRequests());
    const [hasFriendRequests, setHasFriendRequests] = useState(false);
    const [showFriendsModal, setShowFriendsModal] = useState(false)
    const handleClose = () => setShowFriendsModal(false);


    const acceptFriend = (userId) => {
        const options = {
            method: "POST",
            url: hostName + '/friends/'+ userId +'/accept',
        };

        axios
            .request(options)
            .then(function (response) {
                window.location.reload();
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                console.log(error);
            });
    };

    const rejectFriend = (userId) => {
        const options = {
            method: "POST",
            url: hostName + '/friends/'+ userId +'/reject',
        };

        axios
            .request(options)
            .then(function (response) {
                window.location.reload();
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                console.log(error);
            });
    };

    const FriendRequestList = () => {
        if (hasFriendRequests) {
            return <ul className={"list-group"}>
                {friendRequests.map((row, i) => (
                    <li className={"list-item"} key={i}>
                        <Card className={"p-2 mb-2"}>
                            <div className={"row flex-row m-2"}>
                                <p className={"p-0"}><b>{row.username}</b> has requested to add you as a friend!</p>
                            </div>
                            <div className={"row flex-row m-2"}>
                                <div className={"col-auto p-0"}>
                                    <MDBBtn onClick={() => rejectFriend(row.id)}
                                        color={'danger'}>
                                        Decline
                                    </MDBBtn>
                                </div>
                                <div className={"col-auto"}>
                                    <MDBBtn
                                        onClick={() => acceptFriend(row.id)}
                                        color={'success'}>
                                        Accept
                                    </MDBBtn>
                                </div>
                            </div>
                        </Card>
                    </li>
                ))}
            </ul>
        } else {
            return <div></div>
        }
    }

    function getUserFriendRequests() {
        const options = {
            method: "GET",
            url: hostName + "/friends/requests",
        };

        if (!hasFetchedFriendRequests) {
            return axios
                .request(options)
                .then(function (response) {
                    let friendRequestList = response.data;
                    var friendsJson = []
                    for (var i=0;i<friendRequestList.friendRequests.length;i++){
                        friendsJson[i] = {
                            'id': friendRequestList.friendRequests[i].id,
                            'username': friendRequestList.friendRequests[i].username,
                            'created': friendRequestList.friendRequests[i].created,
                        }
                    }
                    //
                    // for (var j = friendsJson.length+1; j<25; j++){
                    //     friendsJson[j] = testFriendsJson
                    // }


                    setFriendRequests(friendsJson);
                    setHasFetchedFriendRequests(true);
                    if (friendsJson.length > 0) {
                        setHasFriendRequests(true);
                    }
                    return friendsJson
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status
                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }
    }


    return (
        <>
            <CoreNavBar loggedIn={localStorage.getItem('access_token') !== null}/>
            <FindFriendsModal show={showFriendsModal} handleClose={handleClose}/>
            <div className={"row flex-row m-4 inbox"}>
                <div className={"row flex-row h-100"}>
                    {/*<div className={"col-4"}>*/}
                        {/*<button className={"bg-blue-500 w-100 rounded-sm h-10 shadow-[0px_0px_0px_3px_rgba(0,0,0)] mt-1"}>*/}
                        {/*    <div className={"flex flex-row align-items-center justify-items-center"}>*/}
                        {/*        <div className="me-auto flex flex-row pl-2 text-white ">*/}
                        {/*            Friends*/}
                        {/*        </div>*/}
                        {/*        <div className="flex flex-row">*/}
                        {/*            <ChevronRight color={"white"} className={"mr-3"}/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</button>*/}
                        {/*<button disabled={true} className={"bg-gray-300 mt-3 w-100 rounded-sm h-10 shadow-[0px_0px_0px_3px_rgba(150,150,150)] mt-1"}>*/}
                        {/*    <div className={"flex flex-row align-items-center justify-items-center"}>*/}
                        {/*        <div className="me-auto flex flex-row pl-2 text-gray-500 ">*/}
                        {/*            Games*/}
                        {/*        </div>*/}
                        {/*        <div className="flex flex-row">*/}
                        {/*            <ChevronRight color={"black"} className={"mr-3"}/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</button>*/}
                        {/*<button disabled={true} className={"bg-gray-300 mt-3 w-100 rounded-sm h-10 shadow-[0px_0px_0px_3px_rgba(150,150,150)] mt-1"}>*/}
                        {/*    <div className={"flex flex-row align-items-center justify-items-center"}>*/}
                        {/*        <div className="me-auto flex flex-row pl-2 text-gray-500 ">*/}
                        {/*            Tournaments*/}
                        {/*        </div>*/}
                        {/*        <div className="flex flex-row">*/}
                        {/*            <ChevronRight color={"black"} className={"mr-3"}/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</button>*/}
                    {/*</div>*/}
                    <div className={"col-12"}>
                        <Card className={"p-3 h-100"}>
                            <Card.Title >
                                <div className={'row justify-content-center align-items-center px-2'}>
                                    <div className={'col-auto  m-0'}>Friend Requests</div>
                                    <MDBBtn className={'col-2 ms-auto'} onClick={() => setShowFriendsModal(true)}><PersonAdd/></MDBBtn>
                                </div>

                                <hr/>
                            </Card.Title>
                            <FriendRequestList/>
                        </Card>
                    </div>
                </div>

            </div>
            <ActionBar valueIn={'requests'}/>
        </>
    );
}

export default RequestsPage