import {Card} from "react-bootstrap";

const CommentView = ({comments}) => {
    return (
        <>
            <ul className={'scrollable-ul list-unstyled h-75'}>
                {comments.map((comment, i) => (
                    <li className="list-item m-2" key={i}>
                        <Card className={'p-2'}>
                            <div className={'row'}>
                                <p className={'col-auto'}><b>{comment.username}</b></p>
                            </div>
                            <div className={'row'}>
                                <p>{comment.comment}</p>
                            </div>
                        </Card>
                    </li>
                ))}
            </ul>
        </>
    );
}

export default CommentView;