import ConfettiExplosion from "react-confetti-explosion";
import React from "react";

const SuccessView = () => {
    return <>
        <img src={"./images/rank_mascot.svg"} className={'w-100'}/>
        <div className={'cent'}>
            {<ConfettiExplosion force={0.8} duration={3000} particleCount={250}
                                width={1600}/>}
        </div>
    </>
}
export default SuccessView;