import {MDBPagination, MDBPaginationItem, MDBPaginationLink} from "mdb-react-ui-kit";
import React from "react";

const Pagination = ({ page, pages, handleSearchAction, search, authorQuery, authorSearch, setPage}) => {

    const click = (newPage) => {
        setPage(newPage);
        handleSearchAction(search, authorQuery, false, authorSearch, newPage);
    }

    const generatePaginationItems = () => {
        let paginationItems = [];

        // Add the previous page link if the current page is not the first page
        if (page > 0) {
            paginationItems.push(
                <MDBPaginationItem className={'pointer'} key="prev">
                    <MDBPaginationLink onClick={() => {click(page - 1)}}>Previous</MDBPaginationLink>
                </MDBPaginationItem>
            );
        }

        // Add the current page and the next 3 pages
        for (let i = page; i <= page + 3 && i <= pages; i++) {
            paginationItems.push(
                <MDBPaginationItem className={'pointer'} key={i} active={i === page} onClick={() => {click(i)}}>
                    <MDBPaginationLink  >{i + 1}</MDBPaginationLink>
                </MDBPaginationItem>
            );
        }

        // Add the next page link if there are more pages
        if (page + 3 < pages) {
            paginationItems.push(
                <MDBPaginationItem className={'pointer'} key="next">
                    <MDBPaginationLink onClick={() => {click(page + 4)}}>Next</MDBPaginationLink>
                </MDBPaginationItem>
            );
        }

        return paginationItems;
    };

    return (
        <MDBPagination className="mb-0 justify-content-center">
            {generatePaginationItems().map((row, i) => (
                row
            ))}
        </MDBPagination>
    );
}
export default Pagination