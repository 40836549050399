import {MDBSpinner} from "mdb-react-ui-kit";
import React from "react";

const Loading = () => {
    return <div className={'d-flex justify-content-center loading-modal align-items-center'}>
        <MDBSpinner role='status'>
            <span className='visually-hidden'>Loading...</span>
        </MDBSpinner>
    </div>
}

export default Loading;