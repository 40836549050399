import hostName from "../constants/hosts";
import axios from "axios";

export function getBook(id, hasBook, setHasBook, setBook) {
    const options = {
        method: "GET",
        url: hostName + '/bookrank/book/' + id,
    };
    if (!hasBook){
        return axios
            .request(options)
            .then(function (response) {
                let book = response.data.book;
                var bookJson= {
                    'id': book.id,
                    'title': book.title,
                    'authors': book.authors,
                    'ranked': book.ranked,
                    'coverEditionKey': book.cover_edition_key,
                    'firstPublishYear': book.first_publish_year,
                    'olKey': book.ol_key
                }
                setHasBook(true);
                setBook(bookJson)
                return bookJson
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
                return []
            });
    }
}


export function addBook(title, authors, callback)  {
    const formData = {
        title: title,
        authors: authors
    }
    const options = {
        method: "POST",
        url: hostName + '/bookrank/books/create',
        data: formData
    };

    axios
        .request(options)
        .then(function (resp) {
            let data = resp.data;
            if (callback) {
                callback(data);
            }
        })
        .catch((err) => {
            let error = err.response ? err.response.data : err;
            // get error status
            let status = err.response.status;
            console.log("status", status);
            if (status === 429) {
                console.log("too many requests", status);
            }
            console.log("catch block...", error);
            return []
        });
}