import React, { useEffect, useState, useRef } from "react";
import { Card } from "react-bootstrap";
import {formatDateTime, getRankingColor} from "../../utils/general";
import Container from "react-bootstrap/Container";
import {
    Bookmark,
    BookmarkFill,
    Chat,
    CheckCircleFill,
    Heart,
    HeartFill,
    PlusCircle,
    PlusCircleFill, Trash
} from "react-bootstrap-icons";
import {commentActivity, getActivity, getActivityFeed, likeActivity, unlikeActivity} from "../../api/activity";
import ActivityCommentsModal from "./ActivityCommentsModal";
import ConfettiExplosion from "react-confetti-explosion";
import {useNavigate} from "react-router-dom";
import {addToQueue, deleteFromQueue} from "../../api/queue";
import Modal from "react-bootstrap/Modal";
import CommentView from "./CommentView";
import {MDBBtn, MDBTextArea} from "mdb-react-ui-kit";
import {ArrowCircleUp} from "@mui/icons-material";
import Button from "react-bootstrap/Button";
import {getCurrentlyReading, unsetCurrentlyReading} from "../../api/currentlyReading";

const ActivityFeed = ({ myActivityFeed, setShowRankingModal, setSelectedRankBook }) => {
    const [hasActivityFeed, setHasActivityFeed] = useState(false);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [activityFeed, setActivityFeed] = useState([]);
    const [likedItems, setLikedItems] = useState([]);
    const [unlikedItems, setUnlikedItems] = useState([]);
    const [queuedBooks, setQueuedBooks] = useState([]);
    const [dequeuedBooks, setDequeuedBooks] = useState([]);
    const [showActivityCommentsModal, setShowActivityCommentsModal] = useState(false);
    const [explosionData, setExplosionData] = useState({ isExploding: false, style: {} });
    const [explosionDataBookmarked, setExplosionDataBookmarked] = useState({ isExploding: false, style: {} });
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [comment, setComment] = useState("");
    const [currentBook, setCurrentBook] = useState(null);
    const [fetchedCurrentBook, setFetchedCurrentBook] = useState(false);

    const navigate = useNavigate();
    const goToBook = (id) => {
        navigate(`/book/${id}`);
    };


    const handleClick = (event) => {
        const rect = event.target.getBoundingClientRect();
        setExplosionData({
            isExploding: true,
            style: {
                position: 'absolute',
                left: rect.left + rect.width / 2 + window.scrollX,
                top: rect.top + rect.height / 2 + window.scrollY,
                transform: 'translate(-50%, -50%)'
            }
        });
        setTimeout(() => setExplosionData({ isExploding: false, style: {} }), 2000);
    };

    const handleClickBookmarked = (event) => {
        const rect = event.target.getBoundingClientRect();
        setExplosionDataBookmarked({
            isExploding: true,
            style: {
                position: 'absolute',
                left: rect.left + rect.width / 2 + window.scrollX,
                top: rect.top + rect.height / 2 + window.scrollY,
                transform: 'translate(-50%, -50%)'
            }
        });
        setTimeout(() => setExplosionDataBookmarked({ isExploding: false, style: {} }), 2000);
    };

    useEffect(() => {
        getActivityFeed(
            myActivityFeed ? '/bookrank/activity/events' : '/bookrank/friends/activity/events',
            hasActivityFeed, setHasActivityFeed, setActivityFeed
        );
    }, [hasActivityFeed]);

    useEffect(() => {
        if (!fetchedCurrentBook){
            getCurrentlyReading((data) => {
                setCurrentBook(data);
                setFetchedCurrentBook(true);
            })
        }
    }, [fetchedCurrentBook, setCurrentBook, currentBook]);

    const showComments = (row) => {
        setSelectedActivity(row);
        setShowActivityCommentsModal(true);
    }

    const isActuallyLiked = (row) => {
        const likedCount = likedItems.filter(id => row.id === id).length;
        const unlikedCount = unlikedItems.filter(id => row.id === id).length;

        if (row.liked) {
            return likedCount === unlikedCount;
        } else {
            return likedCount > unlikedCount;
        }
    }


    const isActuallyQueued = (queued, bookId) => {
        const queuedCount = queuedBooks.filter(id => bookId === id).length;
        const dequeuedCount = dequeuedBooks.filter(id => bookId === id).length;

        if (queued) {
            return queuedCount === dequeuedCount;
        } else {
            return queuedCount > dequeuedCount;
        }
    }

    function getBookmarkIcon(queued, bookId) {
        if (isActuallyQueued(queued, bookId)) {
            return <BookmarkFill color={'green'} className={'h-50 col-auto'} onClick={()=> {
                setDequeuedBooks(prev => [...prev, bookId]);
                deleteFromQueue(bookId);
            }}/>
        } else {
            return <Bookmark className={'h-50 col-auto'} onClick={(event) => {
                handleClickBookmarked(event);
                setQueuedBooks(prev => [...prev, bookId]);
                addToQueue(bookId, null);
            }}/>
        }
    }

    function getHeartButton(row) {
        if (isActuallyLiked(row)) {
            return <HeartFill color="red" onClick={() => { unlikeActivity(row.id, setLikedItems, likedItems, setUnlikedItems, unlikedItems) }} />
        } else {
            return <Heart onClick={(event) => {
                likeActivity(row.id, setLikedItems, likedItems, setUnlikedItems, unlikedItems);
                handleClick(event);
            }}/>
        }
    }

    const getLikeCount = (row) => {
        let count = row.likes.length;

            count += likedItems.filter(id => id === row.id).length;

        if (unlikedItems.includes(row.id)) {
            count -= unlikedItems.filter(id => id === row.id).length;
        }
        return count;
    }

    return (
        <>
            {showActivityCommentsModal && selectedActivity !== null && selectedActivity !== undefined ? (
                // <ActivityCommentsModal show={showActivityCommentsModal} setShow={setShowActivityCommentsModal} activity={selectedActivity} />
                <Modal show={showActivityCommentsModal} className={'overflow-y-auto lower-modal'}
                       dialogClassName={'overflow-y-auto lower-modal'} onHide={() => {
                    setShowActivityCommentsModal(false);
                }} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body style={{maxHeight: '80vh'}} className={'h-100 scrollable-body'}>
                        {showActivityCommentsModal ? (
                            <>
                                <Card className={"p-2 mb-2"}>
                                    <Card.Title>
                                        <div className={'row'}>
                                            <div className={'col-auto'}>
                                                <p className={getRankingColor(selectedActivity.rank)}>{selectedActivity.rank}</p>
                                            </div>
                                            <div className={'col-auto'}><b>
                                                {selectedActivity.username}
                                            </b></div>
                                            <div className={'col-auto'}>{selectedActivity.eventType === "currently_reading" ? "is reading" : "ranked"}</div>
                                            <div className={'col-auto'}>
                                                {selectedActivity.title}
                                            </div>
                                        </div>

                                    </Card.Title>
                                    <div className={"row flex m-2 align-content-center"}>
                                        <div className={'col-auto'}>
                                            <p>Authors: {selectedActivity.authors}</p>
                                            <p>{selectedActivity.timestamp}</p>
                                        </div>
                                    </div>
                                    <div className="row h-auto m-2">
                                        <div
                                            className={'col-auto position-relative px-0'}>{getHeartButton(selectedActivity)}</div>
                                        <div className={'col-auto'}><p
                                            className={'m-0'}>{getLikeCount(selectedActivity)}</p></div>
                                        <div className={'col-auto ms-auto'}><p>{formatDateTime(selectedActivity.timestamp)}</p></div>
                                        <div className={isMobile ? 'col-auto ms-auto' : 'col-auto'}>
                                            {selectedActivity.ranked ? (
                                                <CheckCircleFill color={'green'}></CheckCircleFill>
                                            ) : (
                                                <PlusCircle onClick={() => {
                                                    setSelectedRankBook(selectedActivity);
                                                    setShowActivityCommentsModal(false);
                                                    setShowRankingModal(true);

                                                }}></PlusCircle>
                                            )}

                                        </div>
                                        <div className={'col-auto'}>
                                            {getBookmarkIcon(selectedActivity.queued, selectedActivity.bookId)}
                                        </div>
                                    </div>
                                </Card>
                                <p><b>Comments</b></p>
                                <hr className={'mt-0'}/>
                                {selectedActivity.comments.length > 0 ? (
                                    <CommentView comments={selectedActivity.comments}/>
                                ) : (<></>)}
                                <div className={'row'}>
                                    <div className={'col-10'}>
                                        <MDBTextArea
                                            value={comment}
                                             id="textAreaExample"
                                             rows="{3}"
                                             onChange={(e) => {
                                                setComment(e.target.value);
                                             }}
                                        />
                                    </div>
                                    <div className={'col-2 align-content-end ms-auto'}>
                                        <ArrowCircleUp onClick={() => {
                                            commentActivity(selectedActivity.id, comment, () => {
                                                getActivity(selectedActivity.id,  setSelectedActivity);
                                                setComment("");
                                            })}
                                        } color={"primary"}/>
                                    </div>
                                </div>
                            </>
                        ) : (<></>)}


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => {
                            setShowActivityCommentsModal(false)
                        }}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            ) : (<></>) }
            {hasActivityFeed && activityFeed.length > 0 ? (
                <ul className="list-group h-100 list-unstyled">
                    {fetchedCurrentBook && currentBook != null && !currentBook.ranked ? (
                        <li className={'list-item mb-2'}>
                            {isMobile ? (
                                <div className={'col-12 p-3 theme-blue text-white rounded-2' }>
                                    <div className={'row'}>

                                        <h2 className={'col-auto'}>Currently reading</h2>
                                        <PlusCircle className={'col-auto ms-auto'} onClick={() => {
                                            setSelectedRankBook(currentBook);
                                            setShowRankingModal(true);
                                        }}></PlusCircle>
                                    </div>
                                    <hr/>
                                    <h4>{currentBook.title}</h4>
                                    <p>By: {currentBook.authors}</p>
                                    <div className={'row px-3'}>
                                        <div
                                            className={'col-auto position-relative px-0'}>{getHeartButton(currentBook)}</div>
                                        <div className={'col-auto'}><p className={'m-0'}>{getLikeCount(currentBook)}</p>
                                        </div>
                                        <div className="col-auto px-0">
                                            <Chat onClick={() => {
                                                showComments(currentBook)
                                            }}/>
                                        </div>
                                        <div className={'col-auto'}><p>{currentBook.comments.length}</p></div>
                                        <div className={'col-auto ms-auto'}>
                                            <Trash color={'white'} onClick={() => {
                                                unsetCurrentlyReading(currentBook.bookId, () => {
                                                    getCurrentlyReading((data) => {
                                                        setCurrentBook(data);
                                                        setFetchedCurrentBook(true);
                                                    })
                                                });
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className={'col-12  p-3 theme-blue rounded-2'}>
                                    <div className={'row'}>
                                        <div className={'col-auto justify-content-center align-items-center'}
                                             style={{height: '100%'}}>
                                            <img src={'./images/reading_mascot.svg'} style={{height: '7em'}}/>
                                        </div>
                                        <div className={'col-8 text-white'}>
                                            <h2>Currently reading</h2>
                                            <hr/>
                                            <h4>{currentBook.title}</h4>
                                            <p>By: {currentBook.authors}</p>
                                        </div>
                                        <div className={'col-auto ms-auto justify-content-center align-content-center'}>
                                            <MDBBtn color={'success'} rounded onClick={() => {
                                                setSelectedRankBook(currentBook);
                                                setShowRankingModal(true);
                                            }}>
                                                Rank it!
                                            </MDBBtn>
                                        </div>
                                    </div>
                                    <div className={'row text-white px-3'}>
                                        <div
                                            className={'col-auto position-relative px-0'}>{getHeartButton(currentBook)}</div>
                                        <div className={'col-auto'}><p className={'m-0'}>{getLikeCount(currentBook)}</p>
                                        </div>
                                        <div className="col-auto px-0">
                                            <Chat onClick={() => {
                                                showComments(currentBook)
                                            }}/>
                                        </div>
                                        <div className={'col-auto'}><p>{currentBook.comments.length}</p></div>
                                        <div className={'col-auto ms-auto'}>
                                            <Trash color={'white'} onClick={() => {
                                                unsetCurrentlyReading(currentBook.bookId, () => {
                                                    getCurrentlyReading((data) => {
                                                        setCurrentBook(data);
                                                        setFetchedCurrentBook(true);
                                                    })
                                                });
                                            }}/>
                                        </div>
                                    </div>

                                </div>
                            )}

                        </li>
                    ) : (
                        <></>
                    )}

                    {activityFeed.map((row, i) => (
                        <li className="list-item" key={i}>
                            <Card
                                className={row.eventType === "currently_reading" ? "p-2 mb-2 glow-card" : "p-2 mb-2 "}>
                                <Card.Title>
                                    <div className="row">
                                        <div className="col-auto">
                                            <p className={getRankingColor(row.rank)}>{row.rank}</p>
                                        </div>
                                        <div className="col-auto"><b>{row.username}</b></div>
                                        <div
                                            className={"col-auto"}>{row.eventType === "currently_reading" ? "is reading" : "ranked"} </div>
                                        <div className="col-auto"
                                            // onClick={() => {goToBook(row.bookId)}}
                                        >{row.title}</div>

                                    </div>
                                </Card.Title>
                                <div className="row flex m-2 align-content-center">
                                    <div className="col-auto">
                                        <p>Authors: {row.authors}</p>
                                    </div>
                                </div>
                                <div className="row flex m-2 align-content-center w-100">
                                    <div className="row h-auto">
                                        <div className={'col-auto position-relative px-0'}>{getHeartButton(row)}</div>
                                        <div className={'col-auto'}><p className={'m-0'}>{getLikeCount(row)}</p></div>
                                        <div className="col-auto px-0">
                                            <Chat onClick={() => {
                                                showComments(row)
                                            }}/>
                                        </div>
                                        <div className={'col-auto'}><p>{row.comments.length}</p></div>
                                        <div className={'col-auto ms-auto'}><p>{formatDateTime(row.timestamp)}</p></div>
                                        <div className={isMobile ? 'col-auto ms-auto' : 'col-auto'}>
                                            {row.ranked ? (
                                                <CheckCircleFill color={'green'}></CheckCircleFill>
                                            ) : (
                                                <PlusCircle onClick={() => {
                                                    setSelectedRankBook(row);
                                                    setShowRankingModal(true);
                                                }}></PlusCircle>
                                            )}

                                        </div>
                                        <div className={'col-auto'}>
                                            {getBookmarkIcon(row.queued, row.bookId)}
                                        </div>
                                    </div>


                                </div>
                            </Card>
                        </li>
                    ))}
                </ul>
            ) : (
                <Container>
                    <div className="row w-100 justify-content-center align-items-center">
                        <div className="col-auto">
                            <div><h3 className="item">You don't have any activity</h3></div>
                            <div><p className="item">Click the kitty at the bottom of your screen to start ranking
                                books!</p></div>
                            <div>
                                <img className="item" src="../../images/confused_mascot.svg"/>
                            </div>
                        </div>
                    </div>
                </Container>
            )}
            {explosionData.isExploding && (
                <div style={explosionData.style}>
                    <ConfettiExplosion force={0.2} duration={2000} particleCount={100} width={500} colors={['red']} zIndex={1002}/>
                </div>
            )}
            {explosionDataBookmarked.isExploding && (
                <div style={explosionDataBookmarked.style}>
                    <ConfettiExplosion force={0.2} duration={2000} particleCount={100} width={500} colors={['green']} zIndex={1002} />
                </div>
            )}
        </>
    );
}

export default ActivityFeed;
