import axios from "axios";
import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import CoreNavBar from "../nav/CoreNavBar";
import hostName from "../../constants/hosts";
import {Button, Toast} from "react-bootstrap";
import {MDBBtn} from "mdb-react-ui-kit";
export const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 354);
    const [showToast, setShowToast] = useState(false);


    const [err, setErr] = useState(null);
    const navigate = useNavigate();
    const signup = () => navigate('/signup');
    const host = hostName

    const submit = async e => {
        e.preventDefault();
        setErr(null);
        const user = {
            username: username,
            password: password
        };

        const dat =  await axios.post(host + '/token/', user,{headers: {
                'Content-Type': 'application/json'
            }}, {withCredentials: true})
            .then(function (response) {
                let data = response.data
                localStorage.clear();
                localStorage.setItem('access_token', data.access);
                localStorage.setItem('refresh_token', data.refresh);
                axios.defaults.headers.common['Authorization'] =
                    `Bearer ${data.access}`;
                window.location.href = '/'
            }) .catch((err) => {


                if (err.response.status >= 400 && err.response.status < 500) {
                    setErr("No active accounts with those credentials.");
                    setShowToast(true);
                } else {
                    let error = err.response ? err.response.data : err;
                    setErr(error);
                    setShowToast(true);
                }

            });
        console.log("errp", dat)
    }
return(
    <>
        <CoreNavBar loggedIn={false}/>
        <div
            className="Auth-form-container bg-theme text-light p-5 vh-100">
            <form className="Auth-form" onSubmit={submit}>
                <div className="Auth-form-content">
                    <img className={'item'} src={'./images/reading_mascot.svg'}/>
                    <h3 className="Auth-form-title">Sign In</h3>
                    <div className="form-group mt-3">
                        <label>Username: </label>
                        <input className="form-control mt-1"
                               placeholder="Enter Username"
                               name='username'
                               type='text' value={username}
                               required
                               onChange={e => setUsername(e.target.value)}/>
                    </div>
                    <div className="form-group mt-3">
                        <label>Password: </label>
                        <input name='password'
                               type="password"
                               className="form-control mt-1"
                               placeholder="Enter password"
                               value={password}
                               required
                               onChange={e => setPassword(e.target.value)}/>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="row">
                            <div className={'col-12'}>
                                <div className={'row'}>
                                    <MDBBtn type='submit' className="px-4 py-2" color={'primary'}>
                                        Submit
                                    </MDBBtn>
                                </div>

                                <div className={'row'}>

                                    <MDBBtn outline rounded className={isMobile ? 'my-3 px-4' : 'my-3 px-4'}
                                            color='primary' onClick={signup}>
                                        Sign up
                                    </MDBBtn>
                                </div>
                            </div>

                        </div>
                        <div className={'row mt-2'}>
                            <Toast className={'w-100 p-0'} show={showToast} bg={'danger'} onClose={() => {
                                setShowToast(false)
                            }}>
                                <Toast.Header bg={'danger'}>
                                    <strong className="me-auto">Error</strong>
                                </Toast.Header>
                                <Toast.Body>{err ? (
                                    <>{err}</>
                                ) : ("There's been an issue. Please try again in a few minutes.")}</Toast.Body>
                            </Toast>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </>

)
}
