import {useEffect, useState} from "react";
import hostName from "../../constants/hosts";
import CoreNavBar from "../nav/CoreNavBar";
import {Button, Card} from "react-bootstrap";
import React from 'react';
import Container from "react-bootstrap/Container";
import {DistributeVertical} from "react-bootstrap-icons";
import RankList from "./RankList";
import ActionBar from "./ActionBar";
import getUserBookList from "../../api/bookList";
import RankingModal from "../ranking/RankingModal";
import FloatingCenterButton from "../util/FloatingCenterButton";
import QueueList from "./QueueList";
import {MDBBtn} from "mdb-react-ui-kit";


const MyList = () => {
    const host = hostName;
    const [hasUserBookList, setHasUserBookList] = useState(false);
    const [userBookListUnliked, setUserBookListUnliked] = useState([]);
    const [userBookList, setUserBookList] = useState([]);
    const [rankingModal, setRankingModal] = useState(false);
    const [efficientList, setEfficientList] = useState(true);
    const [queueList, setQueueList] = useState(false);
    const [selectedRankBook, setSelectedRankBook] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        if (!hasUserBookList) {
            getUserBookList(host + '/bookrank/book-list', hasUserBookList, setHasUserBookList, setUserBookList, setUserBookListUnliked)
        }
    }, [setHasUserBookList, setUserBookList, setUserBookListUnliked, hasUserBookList]);

    if(localStorage.getItem('access_token') === null){
        window.location.href = '/login'
    } else {
        return <>
            <CoreNavBar transparent={false} loggedIn={localStorage.getItem('access_token') !== null }/>
            {hasUserBookList ? (
                <RankingModal show={rankingModal} handleClose={() => setRankingModal(false)}
                              bookList={userBookList} bookListUnliked={userBookListUnliked} preSelectedBook={selectedRankBook}/>
            ) : (
                <div></div>
            )}
            <Container className={'col-auto adjusted-container'}>
                <div className={'col-auto h-100 pb-1 overflow-hidden'}>
                    <div className={'row h-auto m-2'}>
                        {/*{!isMobile ? (*/}
                        {/*    <Card*/}
                        {/*        className={isMobile ? 'col-5 align-items-center justify-content-center vw-100 h-50 hover-overlay' : 'col-5 mx-4 align-content-center justify-content-center h-50 pointer hover-overlay'}*/}
                        {/*        onClick={() => setRankingModal(true)}>*/}
                        {/*        /!*<PlusCircle className={'w-50 h-50 cent'} color={'green'}/>*!/*/}
                        {/*        <Card.Img src={"./images/loaf_mascot_2.svg"} className={'w-100'} variant={'top'}>*/}
                        {/*        </Card.Img>*/}
                        {/*        <div className="overlay">*/}
                        {/*            <h3>Click to Rank</h3>*/}
                        {/*        </div>*/}
                        {/*        {isMobile ? (<h5>Click to rank your books</h5>) : (*/}
                        {/*            <></>*/}
                        {/*        )}*/}
                        {/*    </Card>*/}
                        {/*) : (*/}
                        {/*    <></>*/}
                        {/*)}*/}
                            <h3 className={'col-6'}> {!queueList ? (<>My Rankings
                                ({(userBookListUnliked.length + userBookList.length)})</>) : 'My Queue'}
                            </h3>
                            <MDBBtn rounded
                                    className={isMobile ? 'ms-auto col-auto list-switch py-0' : 'ms-auto col-auto list-switch'}
                                    outline={queueList}
                                    onClick={() => {
                                        setQueueList(false)
                                    }}
                            >
                                Read
                            </MDBBtn>
                            <MDBBtn rounded
                                    className={isMobile ? 'ms-auto col-auto list-switch py-0' : 'mx-2 col-auto list-switch'}
                                    outline={!queueList}
                                    onClick={() => {
                                        setQueueList(true)
                                    }}
                            >
                                Want to read
                            </MDBBtn>


                    </div>
                    <div className={isMobile ? 'h-100 w-100' : 'col-12 mx-1 p-2 h-100'}>
                        {/*<Card.Title className={'row p-3'}>*/}
                        {/*    <div align={"end"} className={'col-12 align-content-end'}>*/}
                        {/*        <Button onClick={() => {*/}
                        {/*            setEfficientList(!efficientList)*/}
                        {/*        }}>*/}
                        {/*            <DistributeVertical/>*/}
                        {/*        </Button>*/}
                        {/*    </div>*/}
                        {/*</Card.Title>*/}
                        {!queueList ? (
                            <RankList
                                hasUserBookList={hasUserBookList}
                                userBookList={userBookList}
                                efficientList={efficientList}
                                userBookListUnliked={userBookListUnliked}
                                myList={true}
                            />
                        ) : (
                            <QueueList myList={true} setSelectedRankBook={setSelectedRankBook}
                                       setShowRankingModal={setRankingModal}/>
                        )}

                    </div>
                </div>


            </Container>
            {!isMobile ? (
                <FloatingCenterButton setRankingModal={setRankingModal}/>
            ) : (<></>)}
            <ActionBar valueIn={'myList'}/>
        </>
    }
}

export default MyList;
