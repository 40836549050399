import axios from "axios";
import React, {useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import hostName from "../../constants/hosts";
import CoreNavBar from "../nav/CoreNavBar";
import {Button, Toast} from "react-bootstrap";
import {MDBBtn} from "mdb-react-ui-kit";

const showErrorToast = (msg, timer) => {
    // toast.error(msg || `Something went wrong! Please try again.`, {
    //     position: "top-right",
    //     autoClose: timer ? timer : 1000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    // });
};

export const Signup = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 402);

    const [err, setErr] = useState(null);
    const [showToast, setShowToast] = useState(false);
    const navigate = useNavigate();
    const host = hostName

    const login = () => navigate('/login');
    const submit = async e => {
        e.preventDefault();
        setErr(null);
        if (password === passwordConfirm) {
            const user = {
                username: username,
                password: password
            };

            const resp = await axios.post(host + '/users/', user,{headers: {
                    'Content-Type': 'application/json'
                }}, {withCredentials: true})
                .then(function (response) {
                    let data = response.data
                    localStorage.clear();
                    localStorage.setItem('access_token', data.token.access);
                    localStorage.setItem('refresh_token', data.token.refresh);
                    axios.defaults.headers.common['Authorization'] =
                        `Bearer ${data.token.access}`;
                    window.location.href = '/'
                })
                .catch((err) => {
                    if (err.response.status >= 400 && err.response.status < 500) {
                        let error = err.response ? err.response.data : err;
                        let status = error.status ? error.status : "Error on signup, please contact support."
                        setErr(status);
                        setShowToast(true);
                        // showErrorToast(status)
                    } else {
                        let error = err.response ? err.response.data : err;
                        console.log(err);
                        // showErrorToast(error)

                        setErr(error);
                        setShowToast(true);

                    }

                });


        } else {
            setErr("Error: Passwords must match.");
            setShowToast(true);
        }
    }
    return(
        <>
        <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <CoreNavBar loggedIn={false}/>

        <div className="col-auto Auth-form-container bg-theme text-light p-5 vh-100">

            <form className="Auth-form" onSubmit={submit}>
                <div className="Auth-form-content">
                    <img className={'item'} src={'./images/reading_mascot.svg'}/>
                    <h3 className="Auth-form-title">Sign Up</h3>
                    <div className="form-group mt-3">
                        <label>Username: </label>
                        <input className="form-control mt-1"
                               placeholder="Enter Username"
                               name='username'
                               type='text' value={username}
                               required
                               onChange={e => setUsername(e.target.value)}/>
                    </div>
                    <div className="form-group mt-3">
                        <label>Password: </label>
                        <input name='password'
                               type="password"
                               className="form-control mt-1"
                               placeholder="Enter password"
                               value={password}
                               required
                               onChange={e => setPassword(e.target.value)}/>
                    </div>
                    <div className="form-group mt-3">
                        <label>Confirm Password: </label>
                        <input name='passwordConfirm'
                               type="password"
                               className="form-control mt-1"
                               placeholder="Confirm password"
                               value={passwordConfirm}
                               required
                               onChange={e => setPasswordConfirm(e.target.value)}/>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="row">
                            <div className={'col-12'}>
                                <div className={'row'}>
                                    <MDBBtn type='submit' className="px-4 py-2" color={'primary'}>
                                        Sign up
                                    </MDBBtn>
                                </div>

                                <div className={'row'}>

                                    <MDBBtn outline rounded className={isMobile ? 'my-3 px-4' : 'my-3 px-4'} color='primary' onClick={login}>
                                        Back to login
                                    </MDBBtn>
                                </div>
                            </div>

                        </div>
                        <div className={'row mt-2'}>
                            <Toast className={'w-100 p-0'} show={showToast} bg={'danger'} onClose={() => {setShowToast(false)}}>
                                <Toast.Header bg={'danger'} >
                                    <strong className="me-auto">Error</strong>
                                </Toast.Header>
                                <Toast.Body>{err ? (
                                    <>{err}</>
                                ): ("There's been an issue. Please try again in a few minutes.")}</Toast.Body>
                            </Toast>
                        </div>
                    </div>

                </div>

            </form>

        </div>
        </>
    )
}
