import CoreNavBar from "../nav/CoreNavBar";
import React, {useState} from "react";
import axios from "axios";
import hostName from "../../constants/hosts";
import {Button, Card, Tooltip} from "react-bootstrap";
import {ChevronRight, List, PersonAdd} from "react-bootstrap-icons";
import FindFriendsModal from "./FindFriendsModal";
import {useNavigate} from "react-router-dom";
import ActionBar from "../list/ActionBar";

const ProfilePage = () => {

    const [hasProfile, setHasProfile] = useState(false);
    const [profile, setProfile] = useState(getProfile(hostName + '/current_user/'))
    const [showFriendsModal, setShowFriendsModal] = useState(false)

    const [hasFetchedFriends, setHasFetchedFriends] = useState(false);
    const [friends, setFriends] = useState(getUserFriendRequests());
    const [hasFriends, setHasFriends] = useState(false);

    const navigate = useNavigate();
    const goToList = (id) => {
        navigate(`/list/user/${id}`);
    };

    const handleClose = () => setShowFriendsModal(false);
    const renderTooltip = (props) => (
        <Tooltip id="tooltip-top" {...props}>
            Achievement Desc.
        </Tooltip>
    );

    function getUserFriendRequests() {
        const options = {
            method: "GET",
            url: hostName + "/friends",
        };

        if (!hasFetchedFriends) {
            return axios
                .request(options)
                .then(function (response) {
                    let friendRequestList = response.data;
                    var friendsJson = []
                    for (var i=0;i<friendRequestList.friends.length;i++){
                        friendsJson[i] = {
                            'id': friendRequestList.friends[i].id,
                            'username': friendRequestList.friends[i].username
                        }
                    }

                    setFriends(friendsJson);
                    setHasFetchedFriends(true);
                    if (friendsJson.length > 0) {
                        setHasFriends(true);
                    }
                    return friendsJson
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status
                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }
    }


    function getProfile(url) {
        const options = {
            method: "GET",
            url: url,
        };

        if (!hasProfile){
            return axios
                .request(options)
                .then(function (response) {
                    let profile = response.data;

                    let profileData = {
                        'username': profile.username
                    }
                    setHasProfile(true);
                    setProfile(profileData);
                    return profileData
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status
                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }
    }
    if(localStorage.getItem('access_token') === null){
        window.location.href = '/login'
    }
    else {
        return (
            <>
                <CoreNavBar loggedIn={localStorage.getItem('access_token') !== null}/>
                <FindFriendsModal show={showFriendsModal} handleClose={handleClose}/>
                <div className={"row flex-row m-4"}>
                    <div className={"col-12 m-0 max-h-100"}>
                        <Card className={"mt-4 max-h-100"}>
                            <Card.Header>
                                <div className={"row flex-row"}>
                                    <div className={"col-6"}>Friends</div>
                                    <div className={"col-auto ms-auto"}>
                                        <Button onClick={() => setShowFriendsModal(true)}>
                                            <PersonAdd></PersonAdd>
                                        </Button>
                                    </div>
                                </div>

                            </Card.Header>
                            <Card.Body>
                                {hasFriends ? (
                                    <ul className={"list-group"}>
                                        {friends.map((row, i) => (
                                            <li className={"row p-2"} onClick={() => goToList(row.id)}>
                                                <div className={'col-auto'}>
                                                    {row.username}
                                                </div>
                                                <div className={"col-auto ms-auto"}>
                                                        <ChevronRight ></ChevronRight>
                                                </div>
                                                <hr className={'my-2'}/>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <Button onClick={() => setShowFriendsModal(true)} variant={"success w-100"}>
                                        Add Friends
                                    </Button>
                                )}

                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <ActionBar valueIn={'friends'}/>


            </>
        )
    }
}

export default ProfilePage
