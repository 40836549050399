import Container from "react-bootstrap/Container";
import {useEffect, useState} from "react";
import {getBook} from "../../api/book";
import {Card} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import {MDBBtn} from "mdb-react-ui-kit";
import {ArrowLeft} from "@mui/icons-material";


const BookDetailsPage = () => {
    const { id } = useParams();
    const [hasBook, setHasBook] = useState(false);
    const [book, setBook] = useState([]);

    useEffect(() => {
        getBook(id, hasBook, setHasBook, setBook);
    }, [hasBook, setHasBook, setBook, id]);

    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1); // This navigates back to the previous page
    };


    return (<>
        <Container>
            <MDBBtn onClick={() => {handleBackClick()}}><ArrowLeft></ArrowLeft></MDBBtn>
            {hasBook ? (
                <Card>
                    <Card.Title>
                        {book.title}
                    </Card.Title>
                </Card>
            ) : ( <>hi</>)}
        </Container>
    </>);
}

export default BookDetailsPage;