import axios from "axios";
import React, {useState} from "react";
import hostName from "../../constants/hosts";
import {Button, Card} from "react-bootstrap";
import {Arrow90degLeft, DistributeVertical} from "react-bootstrap-icons";
import RankList from "../list/RankList";
import {useNavigate, useParams} from "react-router-dom";
import CoreNavBar from "../nav/CoreNavBar";
import ActionBar from "../list/ActionBar";
import {MDBBtn} from "mdb-react-ui-kit";
import Container from "react-bootstrap/Container";

const FriendRankListPage = () => {
    const { id } = useParams();
    const [hasUserBookList, setHasUserBookList] = useState(false);
    const [userBookList, setUserBookList] = useState(getUserBookList(hostName + '/bookrank/book-list/' + id));
    const [userBookListUnliked, setUserBookListUnliked] = useState([]);
    const [efficientList, setEfficientList] = useState(true);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const navigate = useNavigate();

    const profile = () => navigate('/profile');


    function getRank(position, elements_len, liked) {
        let rawRank;
        if (liked) {
            rawRank = 5 + (5 * (elements_len - position) / (elements_len + 1));
        } else {
            rawRank = 5 * (elements_len - position) / (elements_len + 1);
        }
        return Math.round(rawRank * 10) / 10;
    }
    function getUserBookList(url) {
        const options = {
            method: "GET",
            url: url,
        };

        if (!hasUserBookList){
            return axios
                .request(options)
                .then(function (response) {
                    let bookSelectList = response.data;
                    var booksJson = []
                    for (var i=0;i<bookSelectList.books.length;i++){
                        booksJson[i] = {
                            'id': bookSelectList.books[i].id,
                            'title': bookSelectList.books[i].title,
                            'rank': getRank(i, bookSelectList.books.length, true),
                            'coverEditionKey': bookSelectList.books[i].cover_edition_key,
                            'authors': bookSelectList.books[i].authors,
                            'firstPublishYear': bookSelectList.books[i].first_publish_year,
                            'olKey': bookSelectList.books[i].ol_key
                        }
                    }
                    var booksJsonUnliked = []
                    for (var i=0;i<bookSelectList.books_unliked.length;i++){
                        booksJsonUnliked[i] = {
                            'id': bookSelectList.books_unliked[i].id,
                            'title': bookSelectList.books_unliked[i].title,
                            'rank': getRank(i, bookSelectList.books_unliked.length, false),
                            'coverEditionKey': bookSelectList.books_unliked[i].cover_edition_key,
                            'authors': bookSelectList.books_unliked[i].authors,
                            'firstPublishYear': bookSelectList.books_unliked[i].first_publish_year,
                            'olKey': bookSelectList.books_unliked[i].ol_key
                        }
                    }
                    setHasUserBookList(true);
                    setUserBookList(booksJson);
                    setUserBookListUnliked(booksJsonUnliked);
                    return booksJson
                })
                .catch((err) => {
                    let error = err.response ? err.response.data : err;
                    // get error status
                    let status = err.response.status;
                    console.log("status", status);
                    if (status === 429) {
                        console.log("too many requests", status);
                    }
                    console.log("catch block...", error);
                    return []
                });
        }
    }

    return (
        <>
            <CoreNavBar loggedIn={localStorage.getItem('access_token') !== null}/>
            <Container className={'p-2 col-auto adjusted-container overflow-hidden'}>
                <div className={'col-auto h-100 pb-1 overflow-hidden'}>
                    <Card className={isMobile ? 'h-100 vw-100 mt-3' : 'mx-1 p-2 h-100'}>
                        <Card.Title className={'row'}>
                            <div className={'col-auto justify-content-center align-content-center'}>
                                <Arrow90degLeft onClick={profile}></Arrow90degLeft>
                            </div>
                            <div className={'col-auto p-3 mx-3'}>Rank List</div>
                            <div align={"end"} className={'col-12 align-content-end'}>
                            </div>
                        </Card.Title>
                        <RankList
                            hasUserBookList={hasUserBookList}
                            userBookList={userBookList}
                            userBookListUnliked={userBookListUnliked}
                            efficientList={efficientList}
                            myList={false}
                        />
                    </Card>
                </div>
            </Container>
        </>
);
}

export default FriendRankListPage;