import {Card} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import React, {useState} from "react";
import ComparisonView from "./ComparisonView";
import LikedView from "./LikedView";

const RankingScreens = ({computeNewComp, userBookList, userBookListUnliked, comparisonBookIndex, selectedBookTitle, comparisonBook, insertRankedBook, selectedBookId, setComparisonBookIndex, setComparisonBook, getMiddleIndex}) => {
    const [liked, setLiked] = useState(null);
    const [hasLiked, setHasLiked] = useState(false);

    function hasComparison(likedLoc, userBookListLoc, userBookListUnlikedLoc) {
        if (likedLoc) {
            return userBookListLoc.length > 0;
        } else {
            return userBookListUnlikedLoc.length > 0;
        }
    }

    return (

        <>
            {hasLiked ? (
                <>{(hasComparison(liked, userBookList, userBookListUnliked)) ? (
                    <ComparisonView
                        computeNewComp={computeNewComp}
                        userBookList={userBookList}
                        userBookListUnliked={userBookListUnliked}
                        comparisonBookIndex={comparisonBookIndex}
                        selectedBookTitle={selectedBookTitle}
                        comparisonBook={comparisonBook}
                        liked={liked}
                    />
                ) : (
                    <Card>
                        <Card.Body>
                            <p>{selectedBookTitle}</p>
                            <Button onClick={() => {
                                insertRankedBook(selectedBookId, 0, liked)
                            }}>Rank</Button>
                        </Card.Body>
                    </Card>
                )}</>
            ):(
                <LikedView
                    setHasLiked={setHasLiked}
                    setLiked={setLiked}
                    setComparisonBookIndex={setComparisonBookIndex}
                    setComparisonBook={setComparisonBook}
                    userBookList={userBookList}
                    userBookListUnliked={userBookListUnliked}
                    title={selectedBookTitle}
                />
            )}

    </>
    );
}

export default RankingScreens;