import Button from "react-bootstrap/Button";
import {Book, BookFill, Bookmark, BookmarkFill, CheckCircleFill, GraphUp, PlusCircle} from "react-bootstrap-icons";
import React, {useState} from "react";
import {addToQueue, deleteFromQueue} from "../../api/queue";
import {setCurrentlyReading, unsetCurrentlyReading} from "../../api/currentlyReading";

const BookItem = ({i, row, rankSelect, rankedBooks, queuedBooks, setQueuedBooks, dequeuedBooks, setDequeuedBooks, currentlyReadingBooks,
                      setCurrentlyReadingBooks,
                      deCurrentlyReading,
                      setDeCurrentlyReading}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const isActuallyQueued = (queued, bookId) => {
        const queuedCount = queuedBooks.filter(id => bookId === id).length;
        const dequeuedCount = dequeuedBooks.filter(id => bookId === id).length;

        if (queued) {
            return queuedCount === dequeuedCount;
        } else {
            return queuedCount > dequeuedCount;
        }
    }

    const isActuallyReading = (reading, bookId) => {
        const readingCount = currentlyReadingBooks.filter(id => bookId === id).length;
        const deCurrentlyReadingCount = deCurrentlyReading.filter(id => bookId === id).length;

        if (reading) {
            return readingCount === deCurrentlyReadingCount;
        } else {
            return readingCount > deCurrentlyReadingCount;
        }
    }

    function getBookIcon(ranked, bookId) {
        if (ranked  || rankedBooks.some((id) => id === bookId)) {

            return <CheckCircleFill color={'green'} className={isMobile ? 'col-auto  h-50 my-2' :'h-50 col-auto'}/>
        } else {
            return <PlusCircle className={isMobile ? ' col-auto  h-50 my-2' :'h-50 col-auto'} onClick={() => {rankSelect(row);}}/>
        }
    }

    function getBookmarkIcon(queued, bookId) {
        if (isActuallyQueued(queued, bookId)) {
            return <BookmarkFill color={'green'} className={isMobile ? ' col-auto h-50 my-2' :'h-50 col-auto'} onClick={()=> {
                setDequeuedBooks(prev => [...prev, bookId]);
                deleteFromQueue(bookId);
            }}/>
        } else {
            return <Bookmark className={isMobile ? ' col-auto h-50 my-2' :'h-50 col-auto'} onClick={() => {
                setQueuedBooks(prev => [...prev, bookId]);
                addToQueue(bookId, null);
            }}/>
        }
    }

    function getReadingIcon(reading, ranked, bookId) {
        if (isActuallyReading(reading, bookId) && !row.ranked) {
            return <BookFill color={'green'}  className={isMobile ? ' col-auto h-50 my-2' :'h-50 col-auto'} onClick={() => {
                setDeCurrentlyReading([bookId]);
                unsetCurrentlyReading(row.id)
            }}/>
        } else {
            return <Book className={isMobile ? ' col-auto h-50 my-2' :'h-50 col-auto'} onClick={() => {
                setCurrentlyReadingBooks([bookId]);
                setCurrentlyReading(row.id, (event) => {

                })
            }}/>
        }
    }

    return <>
        <li className={"list-item hover:bg-green-300"}>
            <div key={i} className={"flex row mt-1 mb-1 px-0"}>
                <div className={'col-4 px-0'}>
                    <img className={'item'}
                         src={'https://covers.openlibrary.org/b/olid/' + row.coverEditionKey + '-M.jpg'}/>
                </div>
                <div className={"col-5 px-0"}>
                    <p><b>{row.title}</b></p>
                    <p className={'min-font'}>Authors: {row.authors}</p>
                </div>
                <div
                    className={'ml-1 col-3 px-2 align-content-center justify-content-center'}>
                    {isMobile ? (
                        <div className={'col-12 align-content-center justify-content-center' }>
                            <div className={'row align-content-center justify-content-center '}>
                                {getBookIcon(row.ranked, row.id)}
                            </div>
                            <div className={'row align-content-center justify-content-center '}>
                                {getBookmarkIcon(row.queued, row.id)}
                            </div>
                            <div className={'row align-content-center justify-content-center '}>
                                {getReadingIcon(row.currentlyReading, row.ranked, row.id)}
                            </div>
                        </div>
                    ): (
                        <div className={'row'}>
                            {getBookIcon(row.ranked, row.id)}
                            {getBookmarkIcon(row.queued, row.id)}
                            {getReadingIcon(row.currentlyReading, row.ranked, row.id)}
                        </div>
                    )}


                    {/*<Button variant={'primary'} disabled={row.ranked || rankedBooks.some((id) => id === row.id)}*/}
                    {/*        className={"h-auto "}*/}
                    {/*        onClick={() => {*/}
                    {/*            rankSelect(row);*/}
                    {/*        }}>*/}
                    {/*    <GraphUp className={'my-2'}/>*/}
                    {/*    /!*  Book list has been retrieved - now do the ranking bit *!/*/}
                    {/*</Button>*/}
                </div>

            </div>
        </li>
    </>
}

export default BookItem;