import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import FormatListNumberedRtlIcon from '@mui/icons-material/FormatListNumberedRtl';
import PeopleIcon from '@mui/icons-material/People';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import getUserBookList from "../../api/bookList";
import hostName from "../../constants/hosts";
import FloatingCenterButton from "../util/FloatingCenterButton";
import RankingModal from "../ranking/RankingModal";

const ActionBar = ({valueIn}) => {
    const [value, setValue] = React.useState(valueIn);
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [hasUserBookList, setHasUserBookList] = useState(false);
    const [userBookListUnliked, setUserBookListUnliked] = useState([]);
    const [userBookList, setUserBookList] = useState([]);
    const [rankingModal, setRankingModal] = useState(false);

    const list = () => navigate('/my-list');
    const activity = () => navigate('/');
    const profile = () => navigate('/profile');
    const requests = () => navigate('/requests');

    useEffect(() => {
        if (!hasUserBookList) {
            getUserBookList(hostName + '/bookrank/book-list', hasUserBookList, setHasUserBookList, setUserBookList, setUserBookListUnliked)
        }
    }, [setHasUserBookList, setUserBookList, setUserBookListUnliked, hasUserBookList]);


    return (
        <>
            {isMobile ? (
                <>
                    {hasUserBookList ? (
                        <RankingModal show={rankingModal} handleClose={() => setRankingModal(false)}
                                      bookList={userBookList} bookListUnliked={userBookListUnliked}/>
                    ) : (
                        <div></div>
                    )}
                    <BottomNavigation sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        width: '100%',
                        backgroundColor: '#fff',
                        boxShadow: '0 -1px 5px rgba(0,0,0,0.2)',
                        zIndex: 1,
                    }} value={value}>
                        <BottomNavigationAction
                            label="Home"
                            value="home"
                            onClick={activity}
                            icon={<HomeIcon />}
                        />
                        <BottomNavigationAction
                            label="My list"
                            value="myList"
                            onClick={list}
                            icon={<FormatListNumberedRtlIcon/>}
                        />
                        <BottomNavigationAction>

                        </BottomNavigationAction>
                        <BottomNavigationAction
                            label="Friends"
                            value="friends"
                            onClick={profile}
                            icon={<PeopleIcon />}
                        />
                        <BottomNavigationAction
                            label="Requests"
                            value="requests"
                            onClick={requests}
                            icon={<NotificationsActiveIcon/>}
                        />
                    </BottomNavigation>
                    <FloatingCenterButton setRankingModal={setRankingModal}/>
                </>
            ) : (
                <></>
            )}
        </>

    );
}

export default ActionBar;