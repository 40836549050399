import {Card} from "react-bootstrap";
import React from "react";
import {HandThumbsDownFill, HandThumbsUpFill} from "react-bootstrap-icons";

const LikedView = ({setLiked, setHasLiked, setComparisonBookIndex, setComparisonBook, userBookList, userBookListUnliked, title}) => {
    function getMiddleIndex(list) {
        return Math.floor(list.length / 2);
    }
    return <div className={'col'}>
        <div className={'row flex-row'}>
            <div className={'col-6'}>
                <Card
                    className={'pointer hover-shad h-100'}
                    onClick={() => {
                        setLiked(true);
                        setHasLiked(true);

                        const middleIndex = getMiddleIndex(userBookList);
                        setComparisonBookIndex(middleIndex);
                        setComparisonBook(userBookList[middleIndex]);
                    }}>
                    <Card.Body className={'align-items-center justify-content-center bg-success rounded'}>
                        <HandThumbsUpFill className={'cent'} color={'white'}/>
                    </Card.Body>
                </Card>
            </div>
            <div className={'col-6'}>
                <Card
                    className={'pointer hover-shad h-100'}
                    onClick={() => {
                        setLiked(false);
                        setHasLiked(true);
                        const middleIndex = getMiddleIndex(userBookListUnliked);
                        setComparisonBookIndex(middleIndex);
                        setComparisonBook(userBookListUnliked[middleIndex]);
                    }}>
                    <Card.Body className={'align-items-center justify-content-center bg-danger rounded'}>
                        <HandThumbsDownFill className={'cent'} color={'white'}/>

                    </Card.Body>
                </Card>
            </div>

        </div>
        <div className={'col align-content-center justify-content-center'}>
            <img className={'item small-image cent-s'}
                 src={"./images/question_mascot.svg"}/>
            <h5 className={'cent-s'}>Did you like {title}?</h5>
        </div>
    </div>
}

export default LikedView;