import {Card} from "react-bootstrap";
import React from "react";
import hostName from "../../constants/hosts";
import axios from "axios";
import {getRankingColor} from "../../utils/general";
import {Trash} from "react-bootstrap-icons";

const RankList = ({hasUserBookList, userBookList, userBookListUnliked, efficientList, myList}) => {
    function deleteRankedBook(selectedBookId) {
        const formData = {
            bookId: selectedBookId
        }
        const options = {
            method: "DELETE",
            url: hostName + '/bookrank/books/delete',
            data: formData
        };

        return axios
            .request(options)
            .then(function (response) {
                window.location.reload();
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
                return []
            });
    }



    if (hasUserBookList && (userBookList.length > 0 || userBookListUnliked.length > 0)) {
        return <ul className={"list-group h-100"}>
            {userBookList.map((row, i) => (
                <li className={"list-item"} key={i}>
                    <Card className={"p-2 mb-2"}>
                        <Card.Title>
                            <div className={'row'}>
                                <div className={'col-auto'}>
                                    <p className={getRankingColor(row.rank)}>{row.rank}</p>
                                </div>
                                <div className={'col-auto'}>
                                    {row.title}
                                </div>
                            </div>

                        </Card.Title>
                        <div className={"row flex m-2 align-content-center"}>
                            {/*{efficientList ? (*/}
                            {/*    <div></div>*/}
                            {/*): (*/}
                            {/*    <div className={'col-auto'}>*/}

                            {/*        <img className={'item'}*/}
                            {/*             src={'https://covers.openlibrary.org/b/olid/' + row.coverEditionKey + '-M.jpg'}/>*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            <div className={'col-auto'}>
                                <p>Authors: {row.authors}</p>
                                <p>First Published: {row.firstPublishYear}</p>
                            </div>

                            {/*<p className={"col-6 p-0"}><b>{row.title}</b> -- {row.rank}</p>*/}
                            {myList ? (
                                <div className={'col-auto d-flex full-height align-items-end justify-content-end ms-auto'}>
                                    <Trash color={'red'}  onClick={() => {deleteRankedBook(row.id)}}/>

                                </div>
                            ) : (
                                <></>
                            )}


                        </div>
                    </Card>
                </li>
            ))}
            {userBookListUnliked.map((row, i) => (
                <li className={"list-item"} key={i}>
                    <Card className={"p-2 mb-2"}>
                        <Card.Title>
                            <div className={'row'}>
                                <div className={'col-auto'}>
                                    <p className={getRankingColor(row.rank)}>{row.rank}</p>
                                </div>
                                <div className={'col-auto'}>
                                    {row.title}
                                </div>
                            </div>

                        </Card.Title>
                        <div className={"row flex m-2 align-content-center"}>
                            {efficientList ? (
                                <div></div>
                            ): (
                                <div className={'col-auto'}>
                                    <img className={'item'}
                                         src={'https://covers.openlibrary.org/b/olid/' + row.coverEditionKey + '-M.jpg'}/>
                                </div>
                            )}

                            <div className={'col-auto'}>
                                <p>Authors: {row.authors}</p>
                                <p>First Published: {row.firstPublishYear}</p>
                            </div>

                            {/*<p className={"col-6 p-0"}><b>{row.title}</b> -- {row.rank}</p>*/}
                            {myList ? (
                                <div className={'col-auto d-flex full-height align-items-center justify-content-center ms-auto '}>
                                    <Trash color={'red'}  onClick={() => {deleteRankedBook(row.id)}}/>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Card>
                </li>
            ))}
        </ul>
    } else if (hasUserBookList && userBookList.length === 0){
        return <div className={'col-12'}>
            <div><h3 className="item">You don't have any ranked books</h3></div>
            <div><p className="item">Click the kitty at the bottom of your screen to start ranking!</p>
            </div>

            <img className={'item'} src={"../../images/confused_mascot.svg"}/>
        </div>
    } {
        return <div></div>
    }
}

export default RankList;