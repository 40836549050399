import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import React, {useEffect, useState} from "react";
import hostName from "../../constants/hosts";
import axios from "axios";
import {MDBBtn} from "mdb-react-ui-kit";
import BookListView from "./BookListView";
import RankingScreens from "./RankingScreens";
import SuccessView from "./SuccessView";
import Loading from "../util/Loading";
import Pagination from "../nav/Pagination";
import AddManually from "./AddManually";
import addManually from "./AddManually";


const RankingModal = ({bookList, bookListUnliked, handleClose, show, preSelectedBook}) => {

    const [searchField, setSearchField] = useState("")
    const [books, setBooks] = useState([])
    const [selectedBookId, setSelectedBookId] = useState(null);
    const [selectedBookTitle, setSelectedBookTitle] = useState(null);
    const [hasUserBookList, setHasUserBookList] = useState(false)
    const [userBookList, setUserBookList] = useState(null);
    const [userBookList2, setUserBookList2] = useState(null);
    const [userBookListUnliked, setUserBookListUnliked] = useState(null);
    const [userBookListUnliked2, setUserBookListUnliked2] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [comparisonBook, setComparisonBook] = useState(null);
    const [comparisonBookIndex, setComparisonBookIndex] = useState(null);
    const [successRank, setSuccessRank] = useState(false);
    const [timer, setTimer] = useState(-1); // Timer set to 10 seconds for this example
    const [authorSearch, setAuthorSearch] = useState(false);
    const [authorQueryField, setAuthorQueryField] = useState("");
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [nextPage, setNextPage] = useState(-1);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [rankedBooks, setRankedBooks] = useState([]);
    const [queuedBooks, setQueuedBooks] = useState([]);
    const [dequeuedBooks, setDequeuedBooks] = useState([]);
    const [currentlyReadingBooks, setCurrentlyReadingBooks] = useState([]);
    const [deCurrentlyReading, setDeCurrentlyReading] = useState([]);
    const [multipleRankings, setMultipleRankings] = useState(false);
    const [manualAdd, setManualAdd] = useState(false);


    // Reset successRank after 1 second
    useEffect(() => {
        if (successRank) {
            if (preSelectedBook !== null && preSelectedBook !== undefined){
                const timer = setTimeout(() => {
                    close();
                    setSuccessRank(false);
                    setHasUserBookList(false);
                }, 1000);
            } else {
                const timer = setTimeout(() => {
                    setSuccessRank(false);
                    setHasUserBookList(false);
                    getUserBookList(hostName + '/bookrank/book-list');
                }, 1000);
            }

            return () => clearTimeout(timer); // Clean up the timeout
        }
    }, [successRank]);

    useEffect(() => {
        if (!hasUserBookList && preSelectedBook !== null && preSelectedBook !== undefined) {
            setSelectedBookId(preSelectedBook.bookId);
            setSelectedBookTitle(preSelectedBook.title);
            if (!multipleRankings) {
                setUserBookList(bookList);
                setUserBookList2(bookList);
                setUserBookListUnliked(bookListUnliked);
                setUserBookListUnliked2(bookListUnliked);
            }

            setHasUserBookList(true);
        }

    }, [hasUserBookList, preSelectedBook]);

    // Timer for typing, we reset this timer when a user takes an action
    // meaning we only search once the user has "paused" for a period of time.
    useEffect(() => {
        if (timer === 0) {
            console.log('Searching NOW');
            if (searchField !== "" && !authorSearch) {
                setProcessing(true);
                searchRequest(searchField, authorQueryField, false, authorSearch, page);
            } else if (authorQueryField !== "" && authorSearch) {
                setProcessing(true);
                searchRequest(searchField, authorQueryField, false, authorSearch, page);
            } else {
                setProcessing(false);
                setSearchField("");
                setBooks([]);
            }
        }

        const timerId = setTimeout(() => {
            if (timer > 0) {
                setTimer(timer - 1);
            }
        }, 1000);

        return () => clearTimeout(timerId);
    }, [timer, searchField, authorQueryField, authorSearch, page]);

    function getMiddleIndex(list) {
        return Math.floor(list.length / 2);
    }

    // Ranking function for our book selection.
    // getMiddleIndex picks the book we want to compare against
    function computeNewComp(newList, rank, liked) {
        // Each time we rate, we discard the half of the ranked books that is on the "other" side of the comparison book.
        // i.e. [a, b, c, d, e] - if we compare newBook to c, and they choose newBook, we discard [c,d, e] and continue rating
        // against [a, b]. If there are more than one book in this new list, we need to continue this until we reach the final book
        // to compare against.
        if (newList.length >= 1) {
            // set the "working" list to the truncated list (aka [a, b] in above example.)
            liked ? setUserBookList(newList) : setUserBookListUnliked(newList);
            // get the approximate middle. In a list of 2, take the lower value.
            const middleIndex = getMiddleIndex(newList);
            // this is the book we're comparing against. in the [a, b] example, it's B. this sets the variables
            // we want for the future to get details about this book when the page loads
            setComparisonBookIndex(middleIndex);
            setComparisonBook(newList[middleIndex]);
        } else {
            // if we've reached this point, there is only one book left to compare against.
            // we can't use the index of the truncated list, so we search userBookList2 (a copy of the original list)
            // for the true index of this book. aka if we're down to [d] the index of this in the trunk list is 0,
            // but the actualIndex in [a, b, c, d] is 3.
            const actualIndex = liked ? userBookList2.indexOf(comparisonBook) : userBookListUnliked2.indexOf(comparisonBook) ;

            // if the user ranks above we add the book above the actual index, and then make the insertion call to the backend
            // from our above example, we'd end with [a, b, c, newBook, d]
            if (rank === "above"){
                const newBookList =  liked ? userBookList2.splice(actualIndex, 0, selectedBookId) : userBookListUnliked2.splice(actualIndex, 0, selectedBookId);
                liked ? setUserBookList2(newBookList) : setUserBookListUnliked2(newBookList);
                insertRankedBook(selectedBookId, actualIndex, liked);
            } else { // below works the same, but we insert under the comparison book: [a, b, c, d, newBook]
                const newBookList =  liked ? userBookList2.splice(actualIndex + 1, 0, selectedBookId) : userBookListUnliked2.splice(actualIndex + 1, 0, selectedBookId);
                liked ? setUserBookList2(newBookList) : setUserBookListUnliked2(newBookList);
                insertRankedBook(selectedBookId, actualIndex + 1, liked);
            }
        }
    }

    function getUserBookList(url){
        const options = {
            method: "GET",
            url: url,
        };

        return axios
            .request(options)
            .then(function (response) {
                let bookSelectList = response.data;
                var booksJson = []
                for (var i=0;i<bookSelectList.books.length;i++){
                    booksJson[i] = {
                        'id': bookSelectList.books[i].id,
                        'title': bookSelectList.books[i].title,
                        'coverEditionKey': bookSelectList.books[i].cover_edition_key,
                        'authors': bookSelectList.books[i].authors,
                        'firstPublishYear': bookSelectList.books[i].first_publish_year,
                        'olKey': bookSelectList.books[i].ol_key
                    }
                }
                var booksJsonUnliked = []
                for (var i=0;i<bookSelectList.books_unliked.length;i++){
                    booksJsonUnliked[i] = {
                        'id': bookSelectList.books_unliked[i].id,
                        'title': bookSelectList.books_unliked[i].title,
                        'coverEditionKey': bookSelectList.books_unliked[i].cover_edition_key,
                        'authors': bookSelectList.books_unliked[i].authors,
                        'firstPublishYear': bookSelectList.books_unliked[i].first_publish_year,
                        'olKey': bookSelectList.books_unliked[i].ol_key
                    }
                }
                setMultipleRankings(true);
                setUserBookList(booksJson)
                setUserBookList2(booksJson)
                setUserBookListUnliked(booksJsonUnliked)
                setUserBookListUnliked2(booksJsonUnliked)
                return booksJson
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
                return []
            });
    }

    function insertRankedBook(selectedBookId, index, liked){
        setRankedBooks((prevBooks) => [...prevBooks, selectedBookId]);
        const formData = {
            bookId: selectedBookId,
            index: index,
            liked: liked
        }
        const options = {
            method: "POST",
            url: hostName + '/bookrank/books/rank',
            data: formData
        };

        return axios
            .request(options)
            .then(function (response) {
                setSuccessRank(true);
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
                return []
            });
    }

    const handleSearchAction = (query, queryAuthor, wide, authorSearchLoc, pageLoc) => {
        setTimer(1);
        setSearchField(query);
        setAuthorQueryField(queryAuthor);
        setProcessing(true);
    }

    const close = () => {
        setHasUserBookList(null);
        setSelectedBookId(null);
        setComparisonBook(null);
        setComparisonBookIndex(null);
        setUserBookList(null);
        setUserBookList2(null);
        setUserBookListUnliked(null);
        setUserBookListUnliked2(null);
        setSuccessRank(false);
        handleClose();
        window.location.reload();
    }

    function getUrl(query, queryAuthor, wide, authorSearchLoc, pageLoc) {
        if (authorSearchLoc) { //books/work/author/<str:author_name>/search/<int:page>
            return {
                method: "GET",
                url: hostName + '/bookrank/books/work/author/'+ queryAuthor +'/search/'+ pageLoc,
            }
        } else {
            const post = wide ? '/search-wide' : '/search'
            return {
                method: "GET",
                url: hostName + '/bookrank/books/work/' + query + post + '/' + pageLoc,
            };
        }

    }
    const searchRequest = (query, queryAuthor, wide, authorSearchLoc, pageLoc) => {
        const options = getUrl(query, queryAuthor, wide, authorSearchLoc, pageLoc)

        axios
            .request(options)
            .then(function (response) {
                let books = response.data;
                var booksJson = []
                for (var i=0;i<books.books.length;i++){
                    booksJson[i] = {
                        'id': books.books[i].id,
                        'title': books.books[i].title,
                        'authors': books.books[i].authors,
                        'ranked': books.books[i].ranked,
                        'queued': books.books[i].queued,
                        'currentlyReading': books.books[i].currently_reading,
                        'coverEditionKey': books.books[i].cover_edition_key,
                        'firstPublishYear': books.books[i].first_publish_year,
                        'olKey': books.books[i].ol_key
                    }
                }
                setNextPage(books.next_page);
                setPages(books.pages);
                setBooks(booksJson);
                setProcessing(false);
                return booksJson
            })
    };

    const rankSelect = (row) => {
        setSelectedBookId(row.id);
        setSelectedBookTitle(row.title);

        if (!multipleRankings) {
            setUserBookList(bookList);
            setUserBookList2(bookList);
            setUserBookListUnliked(bookListUnliked);
            setUserBookListUnliked2(bookListUnliked);
        }

        setHasUserBookList(true);
    }


    return (

        <Modal show={show} onHide={close} backdrop="static" keyboard={false} >
            <Modal.Header closeButton>
                <Modal.Title>Search</Modal.Title>
            </Modal.Header>
            {hasUserBookList ? (<></>) : (
                <>
                    <div className={"row p-3"}>
                        <form className={"col-auto"}>
                            <label>
                                <input
                                    placeholder={authorSearch ? "Author" : "Title"}
                                    className="form-control mt-0 rounded-md border-2 z-10 border-black shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2  hover:shadow transition duration-200 bg-white"
                                    onChange={e => {
                                        authorSearch ? handleSearchAction(e.target.value, e.target.value, false, authorSearch, page) :
                                            handleSearchAction(e.target.value, e.target.value, false, authorSearch, page)

                                    }
                                    }/>
                            </label>
                        </form>
                        <MDBBtn outline={!authorSearch}
                                className={isMobile ? 'mx-2 mt-2 col-auto h-25' : 'col-auto h-25'}
                                rounded onClick={() => {
                            if (authorSearch) {
                                if (searchField !== "") {
                                    setProcessing(true);
                                    searchRequest(searchField, authorQueryField, false, false, page);
                                }
                            } else {
                                if (authorQueryField !== "") {
                                    setProcessing(true);
                                    searchRequest(searchField, authorQueryField, false, true, page);
                                }
                            }
                            setAuthorSearch(!authorSearch);
                        }}>
                            {authorSearch ? (
                                <>Search by Title</>
                            ) : (
                                <>Search by Author</>
                            )}

                        </MDBBtn>
                    </div>
                    <div className={'row row-gutterless px-4 pb-3 align-items-center justify-content-center'}>
                        <MDBBtn rounded className={'mx-3 h-25'} onClick={() => {
                            setManualAdd(!manualAdd)
                        }}>
                            {manualAdd ? "Back to search" : "Can't find your book?"}
                        </MDBBtn>
                    </div>
                </>
            )}

            <Modal.Body style={{maxHeight: '65vh'}} className={'overflow-scroll h-100'}>
                {/* this means we've set the list of rated books. We do this once a user clicks to rate a book*/}
                {hasUserBookList ? (
                    <>
                        {/*If they've finished ranking, we insert the new book and then set this flag*/}
                        {successRank ? (
                            <SuccessView/>
                        ) : (
                            <RankingScreens
                                computeNewComp={computeNewComp}
                                userBookList={userBookList}
                                userBookListUnliked={userBookListUnliked}
                                comparisonBookIndex={comparisonBookIndex}
                                selectedBookTitle={selectedBookTitle}
                                comparisonBook={comparisonBook}
                                insertRankedBook={insertRankedBook}
                                selectedBookId={selectedBookId}
                                setComparisonBook={setComparisonBook}
                                setComparisonBookIndex={setComparisonBookIndex}
                                getMiddleIndex={getMiddleIndex}
                            />
                        )}
                    </>
                ) : (
                    <>
                        {manualAdd ? (
                            <AddManually rankSelect={rankSelect}/>
                        ) : (
                            <div className={"m-1 overflow-hidden"}>
                                {/* Processing flag is set by the timer. When we complete the search after a user pauses, we
                                reset processing flag to false and the timer to -1.*/}
                                {processing ? (
                                    <Loading/>
                                ) : (
                                    <BookListView booksLocal={books}
                                                  rankSelect={rankSelect}
                                                  authorQuery={authorQueryField}
                                                  rankedBooks={rankedBooks}
                                                  queuedBooks={queuedBooks}
                                                  setQueuedBooks={setQueuedBooks}
                                                  dequeuedBooks={dequeuedBooks}
                                                  setDequeuedBooks={setDequeuedBooks}
                                                  currentlyReadingBooks={currentlyReadingBooks}
                                                  setCurrentlyReadingBooks={setCurrentlyReadingBooks}
                                                  deCurrentlyReading={deCurrentlyReading}
                                                  setDeCurrentlyReading={setDeCurrentlyReading}

                                    />
                                )}
                            </div>
                        )}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer className={'row row-gutterless'}>
                <nav>
                    <Pagination
                        page={page}
                        pages={pages}
                        handleSearchAction={handleSearchAction}
                        search={searchField}
                        authorQuery={authorQueryField}
                        authorSearch={authorSearch}
                        setPage={setPage}
                    />
                </nav>
            </Modal.Footer>
        </Modal>

    );
}

export default RankingModal