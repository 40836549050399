import React from "react";
import BookItem from "./BookItem";

const BookListView = ({booksLocal,
                          rankSelect,
                          rankedBooks,
                          queuedBooks,
                          setQueuedBooks,
                          dequeuedBooks,
                          setDequeuedBooks,
                          currentlyReadingBooks,
                          setCurrentlyReadingBooks,
                          deCurrentlyReading,
                          setDeCurrentlyReading}) => {

    return (
        <>
            {booksLocal.length !== 0 ? (
                <>
                    <ul className={"px-0 list-unstyled h-100 overflow-hidden"}>
                        {booksLocal.map((row, i) => (
                            <BookItem
                                row={row} i={i}
                                rankSelect={rankSelect}
                                rankedBooks={rankedBooks}
                                queuedBooks={queuedBooks}
                                setQueuedBooks={setQueuedBooks}
                                dequeuedBooks={dequeuedBooks}
                                setDequeuedBooks={setDequeuedBooks}
                                currentlyReadingBooks={currentlyReadingBooks}
                                setCurrentlyReadingBooks={setCurrentlyReadingBooks}
                                deCurrentlyReading={deCurrentlyReading}
                                setDeCurrentlyReading={setDeCurrentlyReading}
                            />
                        ))}

                    </ul>
                </>
            ) : (
                <div className={"m-4 col"}>
                    <h5>Search for the book you want to rank!</h5>
                    <img className={'item'} src={"./images/search_mascot.svg"}/>
                </div>
            )}
        </>
    );
}

export default BookListView;