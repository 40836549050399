import {MDBBtn, MDBInput} from "mdb-react-ui-kit";
import {addBook} from "../../api/book";
import {useState} from "react";

const AddManually = ({rankSelect}) => {
    const [title, setTitle] = useState(null);
    const [authors, setAuthors] = useState(null);

    return (
        <>
            <div className={'col-auto'}>
                <MDBInput label={'Title'} className={'mb-2'} onChange={(event) => {setTitle(event.target.value)}}/>
                <MDBInput label={'Author(s)'} className={'mb-2'} onChange={(event) => {setAuthors(event.target.value)}}/>
                <div className={'row px-2'}>
                    <MDBBtn className={'col-auto ms-auto'} onClick={() => {
                        addBook(title, authors, (data) => {
                            rankSelect(data);
                        })
                    }}>Rank it!</MDBBtn>
                </div>
            </div>

        </>
    );
}

export default AddManually;