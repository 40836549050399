import axios from "axios";
import hostName from "../constants/hosts";

export function getQueue(queueList, setQueueList, hasQueueList, setHasQueueList) {
    const options = {
        method: "GET",
        url: hostName + '/bookrank/queue',
    };

    if (!hasQueueList){
        return axios
            .request(options)
            .then(function (response) {
                let queueList = response.data;
                var booksJson = []
                for (var i=0;i<queueList.queue.length;i++){
                    booksJson[i] = {
                        'id': queueList.queue[i].id,
                        'workId': queueList.queue[i].work_id,
                        'bookId': queueList.queue[i].work_id,
                        'title': queueList.queue[i].title,
                        'authors': queueList.queue[i].authors,
                        'note': queueList.queue[i].note,
                        'timestamp': queueList.queue[i].timestamp,
                        'ranked': queueList.queue[i].ranked
                    }
                }

                setHasQueueList(true);
                setQueueList(booksJson);
                return booksJson
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
                return []
            });
    }
}

export function deleteFromQueue(workId, callback)  {
    const formData = {
        workId: workId
    }
    const options = {
        method: "DELETE",
        url: hostName + '/bookrank/queue',
        data: formData
    };

    axios
        .request(options)
        .then(function (response) {
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            let error = err.response ? err.response.data : err;
            // get error status
            let status = err.response.status;
            console.log("status", status);
            if (status === 429) {
                console.log("too many requests", status);
            }
            console.log("catch block...", error);
            return []
        });
}

export function addToQueue(id, notes)  {
    const formData = {
        workId: id,
        notes: notes
    }
    const options = {
        method: "POST",
        url: hostName + '/bookrank/queue',
        data: formData
    };

    axios
        .request(options)
        .catch((err) => {
            let error = err.response ? err.response.data : err;
            // get error status
            let status = err.response.status;
            console.log("status", status);
            if (status === 429) {
                console.log("too many requests", status);
            }
            console.log("catch block...", error);
            return []
        });
}

export function editQueueItem(id, note, callback)  {
    const formData = {
        id: id,
        note: note
    }
    const options = {
        method: "PUT",
        url: hostName + '/bookrank/queue',
        data: formData
    };

    axios
        .request(options)
        .then(function (resp) {
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            let error = err.response ? err.response.data : err;
            // get error status
            let status = err.response.status;
            console.log("status", status);
            if (status === 429) {
                console.log("too many requests", status);
            }
            console.log("catch block...", error);
            return []
        });
}