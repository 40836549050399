import React, {useState} from "react";

const FloatingCenterButton = ({setRankingModal}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    return <>
            <button className={isMobile ? "floating-button" : "floating-button-left"} onClick={() => setRankingModal(true)}>
                <img src="./images/loaf_mascot_2.svg" alt="Loaf Mascot" className="floating-button-img"/>
            </button>

    </>
}

export default FloatingCenterButton;