import { Card } from "react-bootstrap";
import React, { useEffect, useState, useRef } from "react";
import { deleteFromQueue, editQueueItem, getQueue } from "../../api/queue";
import {
    ArrowCounterclockwise,
    CheckCircleFill,
    Floppy,
    PencilSquare,
    PlusCircle,
    Trash,
    X
} from "react-bootstrap-icons";

const QueueList = ({myList, setSelectedRankBook, setShowRankingModal}) => {
    const [hasQueueList, setHasQueueList] = useState(false);
    const [queueList, setQueueList] = useState(false);
    const [editId, setEditId] = useState(null);
    const [note, setNote] = useState(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (!hasQueueList) {
            getQueue(queueList, setQueueList, hasQueueList, setHasQueueList);
        }
    }, [hasQueueList, queueList, setQueueList, setHasQueueList]);

    useEffect(() => {
        if (inputRef.current) {
            adjustHeight(inputRef.current);
        }
    }, [note]);

    const adjustHeight = (element) => {
        element.style.height = "auto";
        element.style.height = element.scrollHeight + "px";
    };

    function getEditFieldOrButton(id) {
        if (editId === id) {
            return (
                <form>
                    <textarea
                          ref={inputRef}
                          value={note || ""}
                          onChange={(e) => setNote(e.target.value)}
                          className={"mx-1 h-50 scaling-input scrollable-container"}
                      />
                    <Floppy
                        className={"ms-auto"}
                        onClick={() => {
                            editQueueItem(id, note, () => {
                                setNote(null);
                                setEditId(null);
                                setHasQueueList(false);
                                getQueue(queueList, setQueueList, hasQueueList, setHasQueueList);
                            });
                        }}
                    />
                </form>
            );
        } else {
            return <></>;
        }
    }

    return (
        <>
            {hasQueueList ? (
                <ul className={"list-group h-100"}>
                    {queueList.map((row, i) => (
                        <li className={"list-item"} key={i}>
                            <Card className={"p-2 mb-2"}>
                                <Card.Title>
                                    <div className={"row"}>
                                        <div className={"col-8"}>{row.title}</div>
                                        <div className={'ms-auto col-4'}>
                                            <div className={'row'}>
                                                {row.ranked ? (
                                                    <CheckCircleFill className={"col-auto ms-auto"} color={'green'}></CheckCircleFill>
                                                ) : (
                                                    <PlusCircle className={"col-auto ms-auto"} onClick={() => {
                                                        setSelectedRankBook(row);
                                                        setShowRankingModal(true);
                                                    }}></PlusCircle>
                                                )}
                                                {(editId === row.id) ? (
                                                    <ArrowCounterclockwise
                                                        className={"col-auto"}
                                                        onClick={() => {
                                                            setEditId(null);
                                                            setNote(null);
                                                        }}
                                                    />
                                                ) : (
                                                    <PencilSquare
                                                        className={"col-auto"}
                                                        onClick={() => {
                                                            setEditId(row.id);
                                                            setNote(row.note);
                                                        }}
                                                    ></PencilSquare>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Card.Title>
                                <div className={"row flex m-2 align-content-center"}>
                                    <div className={"col-9"}>
                                        <p>Authors: {row.authors}</p>
                                        <div className={"row"}>
                                            <p className={"col-auto"}>Notes: </p>
                                            <div className={"col-auto"}>
                                                {row.note && (!editId || editId !== row.id) ? (
                                                    <div className={"row"}>
                                                        <div className={"col-10"}>{row.note}</div>
                                                    </div>
                                                ) : (
                                                    getEditFieldOrButton(row.id)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {myList ? (
                                        <div className={"col-2 d-flex full-height align-items-end justify-content-end ms-auto"}>
                                            <Trash
                                                color={"red"}
                                                onClick={() => {
                                                    deleteFromQueue(row.workId, () => {
                                                        setHasQueueList(false);
                                                        getQueue(queueList, setQueueList, hasQueueList, setHasQueueList);
                                                    });
                                                }}
                                            >
                                                Delete
                                            </Trash>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </Card>
                        </li>
                    ))}
                </ul>
            ) : (
                <></>
            )}
        </>
    );
};

export default QueueList;
