import Dropdown from 'react-bootstrap/Dropdown';
import Nav from "react-bootstrap/Nav";
import {NavDropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {MDBBadge, MDBIcon} from "mdb-react-ui-kit";
import hostName from "../../constants/hosts";
import axios from "axios";
import {useState} from "react";

const ProfileDropdown = () => {
    const navigate = useNavigate();
    const logout = () => navigate('/logout');
    const profile = () => navigate('/profile');
    const requests = () => navigate('/requests');
    const support = () => navigate('/support');
    const roadmap = () => navigate('/roadmap');


    return (
        <Nav>
            <NavDropdown
                align="end"
                title={
                    <div className="pull-left">
                        <img className="rounded-circle d-inline-block align-top"
                             src={"./images/img.png"}
                             alt="user pic"
                             width="50"
                             height="50"
                        />
                    </div>

                }
                id="basic-nav-dropdown">

                <NavDropdown.Item onClick={profile}>Friends</NavDropdown.Item>
                <NavDropdown.Item onClick={requests}>
                    Requests
                </NavDropdown.Item>
                <NavDropdown.Item onClick={support}>Report an issue</NavDropdown.Item>
                <NavDropdown.Item onClick={roadmap}>Roadmap</NavDropdown.Item>
                <NavDropdown.Divider />
                <Nav>
                    <Nav.Link onClick={logout}>
                        Logout
                    </Nav.Link>
                </Nav>
            </NavDropdown>
        </Nav>
    );
}

export default ProfileDropdown;