import CoreNavBar from "../nav/CoreNavBar";
import Container from "react-bootstrap/Container";
import {Star, StarFill} from "react-bootstrap-icons";
import {useState} from "react";
import {MDBBtn} from "mdb-react-ui-kit";
import {useNavigate} from "react-router-dom";

const HomePage = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const navigate = useNavigate();
    const login = () => navigate('/login');
    const signup = () => navigate('/signup');

    if (isMobile) {
        return (
            <>
                <CoreNavBar loggedIn={localStorage.getItem('access_token') !== null}/>
                <Container>
                    <div className={'row p-5 vw-100'}>
                        <div className={'col-12'}>
                            <h1>ListKitty</h1>
                            <h3>The book rating app totally made by human people.</h3>
                            <p>(and definitely not by cats)</p>
                            <img className={'home-book-images'} src="./images/loaf_mascot.svg"/>
                            <div className={'row align-content-center justify-content-center px-0'}>
                                <div className={'col-auto'}>
                                    <MDBBtn rounded outline onClick={login}>
                                        Login
                                    </MDBBtn>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'row home-container-blue-mobile p-5 vw-100'}>
                        <div className={'col-12 '}>
                            <h3 className={'left-pg'}>Compare your latest book to ones you've already read.</h3>
                            <p className={'left-pg'}>Then, we generate ratings according to your purrr-eferences.</p>
                            <img className={'home-book-images'} src={'./images/fourth_wing.jpg'}/>
                            <div className={'row align-content-center justify-content-center'}>
                                <h2 className={'col-auto py-3 text-white'}>VS</h2>
                            </div>
                            <div className={'row'}>
                                <img className={'col-auto home-book-images ms-auto'} src={'./images/iron_gold.jpg'}/>
                            </div>
                            <div className={'row align-content-center justify-content-center'}>
                                <div className={'col-auto text-white py-5'}>
                                    (
                                    <StarFill color={'white'} className={'col-auto px-1'}/>
                                    <StarFill color={'white'} className={'col-auto px-1'}/>
                                    <StarFill color={'white'} className={'col-auto px-1'}/>
                                    <StarFill color={'white'} className={'col-auto px-1'}/>
                                    <StarFill color={'white'} className={'col-auto px-1'}/>
                                    is so 10 years ago
                                    )
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'row home-container-tan-mobile vw-100'}>
                        <div className={'col-12 p-5'}>
                            <h2>Add friends, get book recommendations, and more!</h2>
                            <h5>ListKitty is in its early stages. As we gather more ratings from users, we plan to
                                release our recommendation engine, so you can find books you enjoy, faster. The more you
                                rate, the smarter our algorithm becomes!</h5>
                            <p>(plus we ar- ... have... cats)</p>
                        </div>
                    </div>
                    <div className={'row home-container-tan-mobile vw-100'}>
                        <div className={'col-12 p-0 vw-100'}>
                            <div className={'row'}>
                            <div className={'col-auto ms-auto px-0 '}>
                                    <img src={'./images/friends_mascot.svg'} className={'home-cats-mobile ms-auto'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'row home-container-tan-mobile vw-100 p-5'}>
                        <div className={'col-12'}>
                            <div className={'row align-content-center justify-content-center px-0'}>
                                <div className={'col-auto'}>
                                    <MDBBtn rounded outline onClick={signup}>
                                        Sign Up today!
                                    </MDBBtn>
                                </div>
                            </div>
                        </div>

                    </div>
                </Container>
            </>
        );
    } else {
        return (<>
            <CoreNavBar loggedIn={localStorage.getItem('access_token') !== null}/>
            <div className={'row p-5'}>
                <div className={'col-6'}>
                    <h1>ListKitty</h1>
                    <h3>The book rating app totally made by human people.</h3>
                    <p>(and definitely not by cats)</p>
                    <div className={'row align-content-center justify-content-center px-0 my-5'}>
                        <div className={'col-6'}>
                            <MDBBtn rounded outline onClick={login}>
                                Login
                            </MDBBtn>
                        </div>
                    </div>
                </div>
                <div className={'col-6'}>
                    <img className={'item'} src="./images/loaf_mascot.svg"/>
                </div>
            </div>
            <div className={'home-container-blue p-5'}>
                <div className={'row  align-items-center justify-content-center align-content-center'}>
                    <div className={'col-auto '}>
                        <h3>Compare your latest book to ones you've already read.</h3>
                        <p>Then, we generate ratings according to your purrr-eferences.</p>
                    </div>
                </div>
                <div className={'row align-items-center justify-content-center'}>
                    <div className={'col-auto'}>
                        <img className={'home-book-images'} src={'./images/fourth_wing.jpg'}/>

                    </div>

                    <div className={'col-auto align-content-center'}>
                        <h2 className={'text-white'}>vs</h2>

                    </div>
                    <div className={'col-auto mt-5'}>
                        <img className={'home-book-images'} src={'./images/iron_gold.jpg'}/>

                    </div>
                </div>
                <div className={'row justify-content-center align-content-center'}>
                    <div className={'col-auto text-white align-content-end py-5'}>
                        (
                        <StarFill color={'white'} className={'col-auto px-1'}/>
                        <StarFill color={'white'} className={'col-auto px-1'}/>
                        <StarFill color={'white'} className={'col-auto px-1'}/>
                        <StarFill color={'white'} className={'col-auto px-1'}/>
                        <StarFill color={'white'} className={'col-auto px-1'}/>
                        is so 10 years ago
                        )
                    </div>
                </div>
                <div className={'row align-content-center justify-content-center'}>
                    <div className={'col-auto'}>
                        <img src={'./images/phone_mockup.png'} className={'home-phone'}/>
                    </div>
                </div>
            </div>
            <div className={'home-container-tan'}>
                <div className={'row'}>
                    <div className={'col-6'}>
                        <img src={'./images/friends_mascot.svg'} className={'home-cats ms-auto'}/>
                    </div>
                    <div className={'ms-auto col-6 p-5'}>
                        <h2>Add friends, get book recommendations, and more!</h2>
                        <h5>ListKitty is in its early stages. As we gather more ratings from users, we plan to release our recommendation engine, so you can find books you enjoy, faster. The more you rate, the smarter our algorithm becomes!</h5>
                        <p>(plus we ar- ... have... cats)</p>
                        <div className={'row justify-content-center align-content-center py-5'}>
                            <MDBBtn rounded outline onClick={signup} className={'w-auto'}>
                                Sign Up today!
                            </MDBBtn>
                        </div>
                        <div className={'row justify-content-center align-content-center'}>
                            <div className={'col-auto my-5'}>
                                <h3>Mobile app coming soon!</h3>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>);
    }
}

export default HomePage;