import axios from "axios";
import {getRank} from "../utils/general";

export default function getUserBookList(url, hasUserBookList, setHasUserBookList, setUserBookList, setUserBookListUnliked) {
    const options = {
        method: "GET",
        url: url,
    };

    if (!hasUserBookList){
        return axios
            .request(options)
            .then(function (response) {
                let bookSelectList = response.data;
                var booksJson = []
                for (var i=0;i<bookSelectList.books.length;i++){
                    booksJson[i] = {
                        'id': bookSelectList.books[i].id,
                        'title': bookSelectList.books[i].title,
                        'rank': getRank(i, bookSelectList.books.length, true),
                        'coverEditionKey': bookSelectList.books[i].cover_edition_key,
                        'authors': bookSelectList.books[i].authors,
                        'firstPublishYear': bookSelectList.books[i].first_publish_year,
                        'olKey': bookSelectList.books[i].ol_key
                    }
                }

                var booksJsonUnliked = []
                for (var i=0;i<bookSelectList.books_unliked.length;i++){
                    booksJsonUnliked[i] = {
                        'id': bookSelectList.books_unliked[i].id,
                        'title': bookSelectList.books_unliked[i].title,
                        'rank': getRank(i, bookSelectList.books_unliked.length, false),
                        'coverEditionKey': bookSelectList.books_unliked[i].cover_edition_key,
                        'authors': bookSelectList.books_unliked[i].authors,
                        'firstPublishYear': bookSelectList.books_unliked[i].first_publish_year,
                        'olKey': bookSelectList.books_unliked[i].ol_key
                    }
                }
                setHasUserBookList(true);
                setUserBookList(booksJson)
                setUserBookListUnliked(booksJsonUnliked)
                return booksJson
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
                return []
            });
    }
}