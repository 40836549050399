import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {useNavigate} from "react-router-dom";
import ProfileDropdown from "./ProfileDropdown";
import {
    MDBCollapse, MDBContainer,
    MDBIcon,
    MDBNavbar,
    MDBNavbarBrand, MDBNavbarItem,
    MDBNavbarLink,
    MDBNavbarNav,
    MDBNavbarToggler
} from "mdb-react-ui-kit";
import {useState} from "react";
import {DropdownDivider} from "react-bootstrap";

const CoreNavBar = ({transparent, loggedIn}) => {
    const navigate = useNavigate();
    const [openNavNoToggler, setOpenNavNoToggler] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // prev 990

    const logout = () => navigate('/logout');
    const login = () => navigate('/login');
    const home = () => navigate('/');
    const myList = () => navigate('/my-list');
    const profile = () => navigate('/profile');
    const requests = () => navigate('/requests');
    const support = () => navigate('/support');
    const roadmap = () => navigate('/roadmap');

    return (
        <>
            {isMobile ? (
                <MDBNavbar className={"text-light front"}>
                    <MDBContainer fluid>
                        <MDBNavbarBrand href="#" onClick={home} className={"flex flex-row"}>
                            <div className={"px-1 flex flex-row"}>{' '}ListKitty</div>
                        </MDBNavbarBrand>
                        <MDBNavbarToggler
                            type='button'
                            data-target='#navbarTogglerDemo01'
                            aria-controls='navbarTogglerDemo01'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => setOpenNavNoToggler(!openNavNoToggler)}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>
                        <MDBCollapse navbar open={openNavNoToggler} className={'row align-items-center'}>
                            <MDBNavbarNav right className={'mb-2 mb-lg-0'}>
                                {loggedIn ? (
                                    <MDBNavbarItem className={isMobile ? '' : 'ms-auto'}>
                                        <MDBNavbarLink onClick={logout}>
                                            Logout
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                ) : (
                                    <MDBNavbarItem className={isMobile ? '' : 'ms-auto'}>
                                        <MDBNavbarLink onClick={login}>
                                            Login
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>)
                                }
                            </MDBNavbarNav>
                        </MDBCollapse>

                    </MDBContainer>
                </MDBNavbar>
            ) : (
                <MDBNavbar expand="lg" className={
                    transparent ? "bg-body-tertiary front transparent text-light navbar-expand" :
                        "text-light front"
                }>
                    <MDBContainer fluid>
                        <MDBNavbarBrand href="#" onClick={home} className={"flex flex-row"}>
                            <div className={"px-1 flex flex-row"}>{' '}ListKitty</div>
                        </MDBNavbarBrand>
                        <MDBNavbarToggler
                            type='button'
                            data-target='#navbarTogglerDemo01'
                            aria-controls='navbarTogglerDemo01'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => setOpenNavNoToggler(!openNavNoToggler)}
                        >
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>
                        <MDBCollapse navbar open={openNavNoToggler} className={'row align-items-center'}>

                            <MDBNavbarNav right className={'mb-2 mb-lg-0'}>
                                <MDBNavbarItem className={'align-content-center justify-items-center'}>
                                    <MDBNavbarLink active aria-current='page' onClick={home}>
                                        Home
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem className={'align-content-center justify-items-center'}>
                                    <MDBNavbarLink onClick={myList}>
                                        My List
                                    </MDBNavbarLink>
                                </MDBNavbarItem>
                                {loggedIn ? (
                                    // <ProfileDropdown/>
                                    <>
                                        {isMobile ? (
                                            <>
                                                <MDBNavbarItem className={isMobile ? '' : 'ms-auto'}>
                                                    <MDBNavbarLink onClick={profile}>
                                                        Friends
                                                    </MDBNavbarLink>
                                                </MDBNavbarItem>
                                                <MDBNavbarItem className={isMobile ? '' : 'ms-auto'}>
                                                    <MDBNavbarLink onClick={requests}>
                                                        Requests
                                                    </MDBNavbarLink>
                                                </MDBNavbarItem>
                                                <MDBNavbarItem className={isMobile ? '' : 'ms-auto'}>
                                                    <MDBNavbarLink onClick={support}>
                                                        Support
                                                    </MDBNavbarLink>
                                                </MDBNavbarItem>
                                                <MDBNavbarItem className={isMobile ? '' : 'ms-auto'}>
                                                    <MDBNavbarLink onClick={roadmap}>
                                                        Roadmap
                                                    </MDBNavbarLink>
                                                </MDBNavbarItem>
                                                <DropdownDivider></DropdownDivider>
                                                <MDBNavbarItem className={isMobile ? '' : 'ms-auto'}>
                                                    <MDBNavbarLink onClick={logout}>
                                                        Logout
                                                    </MDBNavbarLink>
                                                </MDBNavbarItem>
                                            </>

                                        ): (
                                            <MDBNavbarItem className={isMobile ? '' : 'ms-auto'}>
                                                <ProfileDropdown/>
                                            </MDBNavbarItem>
                                        )}
                                        {/*<MDBNavbarItem className={isMobile ? '' : 'ms-auto'}>*/}
                                        {/*    */}

                                        {/*    <MDBNavbarLink onClick={logout}>*/}
                                        {/*        Logout*/}
                                        {/*    </MDBNavbarLink>*/}
                                        {/*</MDBNavbarItem>*/}
                                    </>

                                ) : (
                                    <MDBNavbarItem className={isMobile ? '' : 'ms-auto'}>
                                        <MDBNavbarLink onClick={login}>
                                            Login
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>)
                                }
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBContainer>
                </MDBNavbar>
            )}

        </>

    );
}

export default CoreNavBar;
