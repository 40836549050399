import hostName from "../constants/hosts";
import axios from "axios";

export function getActivityFeed(url, hasActivityFeed, setHasActivityFeed, setActivityFeed) {
    const options = {
        method: "GET",
        url: hostName + url,
    };
    if (!hasActivityFeed){
        return axios
            .request(options)
            .then(function (response) {
                let bookSelectList = response.data;
                var booksJson = []
                for (var i=0;i<bookSelectList.events.length;i++){
                    booksJson[i] = {
                        'id': bookSelectList.events[i].id,
                        'bookId': bookSelectList.events[i].work_id,
                        'title': bookSelectList.events[i].work_title,
                        'username': bookSelectList.events[i].username,
                        'likes': bookSelectList.events[i].likes,
                        'comments': bookSelectList.events[i].comments,
                        'liked': bookSelectList.events[i].liked,
                        'queued': bookSelectList.events[i].queued,
                        'rank': bookSelectList.events[i].rank,
                        'eventType': bookSelectList.events[i].event_type,
                        'authors': bookSelectList.events[i].work_authors,
                        'ranked': bookSelectList.events[i].ranked,
                        'timestamp': bookSelectList.events[i].timestamp
                    }
                }

                setHasActivityFeed(true);
                setActivityFeed(booksJson)
                return booksJson
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
                return []
            });
    }
}

export function getActivity(id, setActivity) {
    const options = {
        method: "GET",
        url: hostName + '/bookrank/activity/events/' + id,
    };
    return axios
        .request(options)
        .then(function (response) {
            let event = response.data.event;
            console.log(event);
            var eventJson = {
                'id': event.id,
                'bookId': event.work_id,
                'title': event.work_title,
                'username': event.username,
                'likes': event.likes,
                'comments': event.comments,
                'liked': event.liked,
                'queued': event.queued,
                'eventType': event.event_type,
                'rank': event.rank,
                'authors': event.work_authors,
                'ranked': event.ranked,
                'timestamp': event.timestamp
            }

            setActivity(eventJson)
            return eventJson
        })
        .catch((err) => {
            let error = err.response ? err.response.data : err;
            // get error status
            let status = err.response.status;
            console.log("status", status);
            if (status === 429) {
                console.log("too many requests", status);
            }
            console.log("catch block...", error);
            return []
        });
}

export function likeActivity(activityId, setLikedItems, likedItems, setUnlikedItems, unlikedItems)  {
    const formData = {
        id: activityId
    }
    const options = {
        method: "POST",
        url: hostName + '/bookrank/activity/events/like',
        data: formData
    };

    axios
        .request(options)
        .then(function (response) {
            setLikedItems(prev => [...prev, activityId]);
        })
        .catch((err) => {
            let error = err.response ? err.response.data : err;
            // get error status
            let status = err.response.status;
            console.log("status", status);
            if (status === 429) {
                console.log("too many requests", status);
            }
            console.log("catch block...", error);
            return []
        });
};

export function commentActivity(activityId, comment, callback)  {
    const formData = {
        id: activityId,
        comment: comment
    }
    const options = {
        method: "POST",
        url: hostName + '/bookrank/activity/events/comment',
        data: formData
    };

    axios
        .request(options)
        .then(function (response) {
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            let error = err.response ? err.response.data : err;
            // get error status
            let status = err.response.status;
            console.log("status", status);
            if (status === 429) {
                console.log("too many requests", status);
            }
            console.log("catch block...", error);
            return []
        });
};

export function unlikeActivity(activityId, setLikedItems, likedItems, setUnlikedItems, unlikedItems)  {
    const formData = {
        id: activityId
    }
    const options = {
        method: "POST",
        url: hostName + '/bookrank/activity/events/unlike',
        data: formData
    };

    axios
        .request(options)
        .then(function (response) {
            setUnlikedItems(prev => [...prev, activityId]);
        })
        .catch((err) => {
            let error = err.response ? err.response.data : err;
            // get error status
            let status = err.response.status;
            console.log("status", status);
            if (status === 429) {
                console.log("too many requests", status);
            }
            console.log("catch block...", error);
            return []
        });
};
