import {Card} from "react-bootstrap";
import React from "react";

const ComparisonView = ({computeNewComp, userBookList, userBookListUnliked, comparisonBookIndex, selectedBookTitle, comparisonBook, liked}) => {
    let compareList = liked ? userBookList : userBookListUnliked
    return <div className={'col'}>
        <div className={'row flex-row'}>
            <div className={'col-6'}>
                <Card
                    className={'pointer hover-shad h-100'}
                    onClick={() => computeNewComp(compareList.slice(0, comparisonBookIndex), "above", liked)}>
                    <Card.Body>
                        {selectedBookTitle}
                    </Card.Body>
                </Card>
            </div>
            <div className={'col-6'}>
                <Card
                    className={'pointer hover-shad h-100'}
                    onClick={() => computeNewComp(compareList.slice(comparisonBookIndex + 1), "below", liked)}>
                    <Card.Body>
                        {comparisonBook.title}
                    </Card.Body>
                </Card>
            </div>

        </div>
        <div className={'col align-content-center justify-content-center'}>
            <img className={'item small-image cent-s'}
                 src={"./images/question_mascot.svg"}/>
            <h5 className={'cent-s'}>Which book did you prefer?</h5>
        </div>
    </div>
}

export default ComparisonView;