import Container from "react-bootstrap/Container";
import ActivityFeed from "./ActivityFeed";
import CoreNavBar from "../nav/CoreNavBar";
import React, {useEffect, useState} from "react";
import ActionBar from "../list/ActionBar";
import FloatingCenterButton from "../util/FloatingCenterButton";
import RankingModal from "../ranking/RankingModal";
import getUserBookList from "../../api/bookList";
import hostName from "../../constants/hosts";
import {MDBBtn} from "mdb-react-ui-kit";

const ActivityPage = () => {
    const [hasUserBookList, setHasUserBookList] = useState(false);
    const [userBookListUnliked, setUserBookListUnliked] = useState([]);
    const [userBookList, setUserBookList] = useState([]);
    const [rankingModal, setRankingModal] = useState(false);
    const [selectedRankBook, setSelectedRankBook] = useState(null);

    useEffect(() => {
        if (!hasUserBookList) {
            getUserBookList(hostName + '/bookrank/book-list', hasUserBookList, setHasUserBookList, setUserBookList, setUserBookListUnliked)
        }
    }, [setHasUserBookList, setUserBookList, setUserBookListUnliked, hasUserBookList]);

    if(localStorage.getItem('access_token') === null){
        window.location.href = '/home'
    } else {
        return (
            <>
                <CoreNavBar transparent={false} loggedIn={localStorage.getItem('access_token') !== null}/>
                {hasUserBookList ? (
                    <RankingModal show={rankingModal} handleClose={() => setRankingModal(false)}
                                  bookList={userBookList} bookListUnliked={userBookListUnliked} preSelectedBook={selectedRankBook}/>
                ) : (
                    <div></div>
                )}
                <Container className={'col-auto adjusted-container overflow-hidden'}>
                    <div className={'p-3'}>
                        <h3>Activity</h3>
                    </div>
                    <div className={'col-auto h-100 pb-1 overflow-hidden'}>

                        <ActivityFeed myActivityFeed={false} setShowRankingModal={setRankingModal}
                                      setSelectedRankBook={setSelectedRankBook}/>
                    </div>
                </Container>
                <FloatingCenterButton setRankingModal={setRankingModal}/>
                <ActionBar valueIn={'home'}/>
            </>

        );
    }
}

export default ActivityPage;