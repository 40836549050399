import './App.css';
import { Routes,Route} from 'react-router-dom';
import React from "react";
import MyList from "./components/list/MyList";
import {Login} from "./components/auth/Login";
import {Signup} from "./components/auth/Signup";
import {Logout} from "./components/auth/Logout";
import ProfilePage from "./components/profile/ProfilePage";
import RequestsPage from "./components/requests/RequestsPage";
import FriendRankListPage from "./components/profile/FriendRankListPage";
import ActivityPage from "./components/activity/ActivityPage";
import BookDetailsPage from "./components/BookDetailsPage/BookDetailsPage";
import HomePage from "./components/home/HomePage";


function App() {
  return (
      <Routes>
          <Route path="/my-list" element={<MyList/>} />
          <Route path="/login" element={<Login/>}/>
          <Route path="/signup" element={<Signup/>}/>
          <Route path="/logout" element={<Logout/>}/>
          <Route path="/profile" element={<ProfilePage/>}/>
          <Route path="/requests" element={<RequestsPage/>}/>
          <Route path="/" element={<ActivityPage/>}/>
          <Route path="/home" element={<HomePage/>}/>
          <Route path="/list/user/:id" element={<FriendRankListPage/>}/>
          <Route path="/book/:id" element={<BookDetailsPage/>}/>
      </Routes>
  );
}

export default App;
