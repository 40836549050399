import hostName from "../constants/hosts";
import axios from "axios";

export function setCurrentlyReading(bookId, callback)  {
    const formData = {
        bookId: bookId,
        type: "currently_reading"
    }
    const options = {
        method: "POST",
        url: hostName + '/bookrank/activity/events',
        data: formData
    };

    axios
        .request(options)
        .then(function (response) {
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            let error = err.response ? err.response.data : err;
            // get error status
            let status = err.response.status;
            console.log("status", status);
            if (status === 429) {
                console.log("too many requests", status);
            }
            console.log("catch block...", error);
            return []
        });
};


export function unsetCurrentlyReading(bookId, callback)  {
    const formData = {
        bookId: bookId
    }
    const options = {
        method: "DELETE",
        url: hostName + '/bookrank/activity/events/currently-reading',
        data: formData
    };

    axios
        .request(options)
        .then(function (response) {
            if (callback) {
                callback();
            }
        })
        .catch((err) => {
            let error = err.response ? err.response.data : err;
            // get error status
            let status = err.response.status;
            console.log("status", status);
            if (status === 429) {
                console.log("too many requests", status);
            }
            console.log("catch block...", error);
            return []
        });
};


export function getCurrentlyReading(callback) {
    const options = {
        method: "GET",
        url: hostName + '/bookrank/activity/events/currently-reading',
    };

        return axios
            .request(options)
            .then(function (response) {
                let event = response.data.event;
                if (event !== null) {
                    var eventJson = {
                        'id': event.id,
                        'bookId': event.work_id,
                        'title': event.work_title,
                        'username': event.username,
                        'likes': event.likes,
                        'comments': event.comments,
                        'liked': event.liked,
                        'queued': event.queued,
                        'rank': event.rank,
                        'authors': event.work_authors,
                        'ranked': event.ranked,
                        'eventType': event.event_type,
                        'timestamp': event.timestamp
                    }
                    if (callback) {
                        callback(eventJson)
                    }
                    return eventJson
                } else {
                    if (callback) {
                        callback(null)
                    }
                }


                return null
            })
            .catch((err) => {
                let error = err.response ? err.response.data : err;
                // get error status
                let status = err.response.status;
                console.log("status", status);
                if (status === 429) {
                    console.log("too many requests", status);
                }
                console.log("catch block...", error);
                return []
            });

}