export const classnames = (...args) => {
    return args.join(" ");
};

export function getRankingColor(rank) {
    if (rank > 7) {
        return "text-success"
    } else if (rank > 5) {
        return "text-warning"
    } else {
        return "text-danger"
    }
}

export function getRank(position, elements_len, liked) {
    let rawRank;
    if (liked) {
        rawRank = 5 + (5 * (elements_len - position) / (elements_len + 1));
    } else {
        rawRank = 5 * (elements_len - position) / (elements_len + 1);
    }
    return Math.round(rawRank * 10) / 10;
}

export function formatDateTime(dateTimeStr) {
    const date = new Date(dateTimeStr);
    const now = new Date();

    // Calculate the difference in calendar days
    const diffTime = now.getTime() - date.getTime();
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    // Check if the date is today
    const isToday = now.getDate() === date.getDate() &&
        now.getMonth() === date.getMonth() &&
        now.getFullYear() === date.getFullYear();

    // Check if the date is yesterday
    const isYesterday = diffDays === 1 &&
        now.getDate() - date.getDate() === 1 &&
        now.getMonth() === date.getMonth() &&
        now.getFullYear() === date.getFullYear();

    if (isToday) {
        return 'today';
    } else if (isYesterday || diffDays === 0) {
        return 'yesterday';
    } else if (diffDays <= 30) {
        return `${diffDays} days ago`;
    } else {
        const month = date.getMonth() + 1; // getMonth() is zero-based
        const day = date.getDate();
        const year = date.getFullYear();
        return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    }
}